<template>
  <section class="accesos-directos">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h3>INFORMACIÓN RELEVANTE</h3>
        </div>
      </div>
      <div class="row mt-5 mb-4 justify-content-around">
        <div
          class="col-12 col-md-4 text-center custom-buttom"
          v-for="access in accesses"
          :key="access.id"
        >
          <img
            :src="access.imageSrc"
            alt="Icono"
            style="width: 100px; height: auto"
          />
          <h5>{{ access.title }}</h5>
          <button
            v-if="access.type === 'modal'"
            class="btn btn-primary"
            @click="handleAccessClick(access)"
          >
            {{ access.buttonText }}
          </button>
          <a
            v-else-if="access.type === 'file'"
            :href="`${config.backendUrl}/storage/documents/Ins12024.pdf`"
            target="_blank"
          >
            <button class="btn btn-primary">{{ access.buttonText }}</button>
          </a>
          <a v-else :href="access.link" target="_blank">
            <button class="btn btn-primary">{{ access.buttonText }}</button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useSweetAlert } from "@/composables/useSweetAlert";
import config from "@/config";

export default {
  name: "AccesosDirectosComponent",
  setup() {
    const { showDetailsModal } = useSweetAlert();
    const router = useRouter();
    const store = useStore();

    const user = computed(() => store.state.user);
    const selectedConcessionaire = computed(
      () => store.state.selectedConcessionaire
    );

    const accesses = ref([
      {
        id: 1,
        title: "Tu Administrador",
        imageSrc: require("@/assets/images/informacion-relevante/Logo-administrador.png"),
        type: "modal",
        buttonText: "Ver",
      },
      {
        id: 2,
        title: "Convocatoria Asamblea",
        imageSrc: require("@/assets/images/informacion-relevante/Logo-asamblea.png"),
        type: "file",
        buttonText: "Descargar",
      },
      {
        id: 3,
        title: "Boletin Oficial",
        imageSrc: require("@/assets/images/informacion-relevante/Logo-boletin.png"),
        type: "link",
        link: "https://www.boletinoficial.gob.ar/detalleAviso/segunda/A1356736/20241210",
        buttonText: "Ingresar",
      },
      {
        id: 4,
        title: "Circulares",
        imageSrc: require("@/assets/images/informacion-relevante/logo-circulares.png"),
        type: "link",
        link: "/socios-circulares",
        buttonText: "Ingresar",
      },
      {
        id: 5,
        title: "Regional",
        imageSrc: require("@/assets/images/informacion-relevante/Logo-regional.png"),
        type: "modal",
        buttonText: "Ingresar",
      },
      {
        id: 6,
        title: "Estadísticas",
        imageSrc: require("@/assets/images/informacion-relevante/Logo-estadisticas.png"),
        type: "link",
        link: "/socios-reportes",
        buttonText: "Ingresar",
      },
      {
        id: 6,
        title: "Contacto",
        imageSrc: require("@/assets/images/informacion-relevante/file-contact.png"),
        type: "modal",
        link: "/contacto",
        buttonText: "Ingresar",
      },
    ]);

    const handleAccessClick = (access) => {

      if (access.link === "/contacto") {
        router.push({ path: access.link, query: { title: "CONTACTO SOCIOS" } });
      } else if (access.type === "link") {
        router.push({ path: access.link });
      } else if (access.id === 1) {
        const title = "Información de tu Administrador";
        const body = user.value?.owner?.name
          ? user.value.owner.name
          : "El concesionario no posee administrador. Ante cualquier consulta podés escribir un email a socios@acara.org.ar";

        const additionalDetails = [
          {
            label: "Email",
            value: user.value?.owner?.email || "Email no disponible",
          },
          {
            label: "Celular",
            value:
              user.value?.owner?.business_phone || "Teléfono no disponible",
          },
        ];

        showDetailsModal(title, body, additionalDetails);
      } else if (access.id === 5) {
        const title = "Información del Regional";
        const body = selectedConcessionaire.value?.regional_name
          ? `Regional: ${selectedConcessionaire.value.regional_name}`
          : "Información regional no disponible";

        const additionalDetails = [
          {
            label: "Email",
            value: selectedConcessionaire.value?.email || "Email no disponible",
          },
          {
            label: "Celular",
            value:
              selectedConcessionaire.value?.business_phone ||
              "Teléfono no disponible",
          },
        ];

        showDetailsModal(title, body, additionalDetails);
      }
    };

    return {
      accesses,
      handleAccessClick,
      config,
    };
  },
};
</script>

<style scoped>
.accesos-directos {
  background: #d9d9d987;
  padding: 25px 0;
}

.accesos-directos h3 {
  text-transform: uppercase;
  color: #005fa8;
  font-family: Inter, sans-serif;
  font-size: 44px;
  font-weight: 700;
  line-height: 77.76px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.accesos-directos h5 {
  text-transform: uppercase;
  color: #021f5b;
  font-weight: 700;
  font-size: 18px;
  line-height: 29.05px;
  text-align: center;
  font-family: Inter, sans-serif;
  padding: 12px 0;
  color: #021f5b;
}

.accesos-directos img {
  font-size: 58px;
  color: #00a0e1;
}

.accesos-directos .btn {
  background: #021f5b;
  border-color: #00999c;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  padding: 5px 35px;
  width: 200px;
  border-radius: 50px;
  text-align: center;
}

.custom-buttom {
  margin-bottom: 55px;
}
</style>

<template>
  <section id="socios">
    <div class="header">
      <div class="title">
        <div class="container">
          <div class="col-12">
            <h2>Socios</h2>
          </div>
        </div>
      </div>
    </div>
 
    <section class="asociarse">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center text-md-left">
            <p>ALTA DE SOCIO<span class="nombre-concesionario"></span></p>
          </div>
        </div>
      </div>
    </section>

    <div id="alta-socio">
      <component
        :is="currentComponent"
        :form-data="formData"
        :is-loading="isLoading"
        :concessionaire-name="formData.concessionaire_name"
        @update-form-data="updateFormData"
        @next="goToNextStep"
        @restart="restartForm"
      />
    </div>
  </section>
</template>


<script>
import { reactive, ref, computed } from "vue";
import { createRegistration } from "@/services/api/registration";
import StepOne from "./steps/StepOne.vue";
import StepTwo from "./steps/StepTwo.vue";
import StepThree from "./steps/StepThree.vue";
import StepFour from "./steps/StepFour.vue";
import Swal from "sweetalert2";

export default {
  name: "MultiStepForm",
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
  },
  setup() {
    const formData = reactive({
      cuit: "",
      dni: "",
      name: "",
      lastname: "",
      email: "",
      personal_phone: "",
      business_phone: "",
      concessionaire_id: "",
      positions: [],
    });

    const isLoading = ref(false);

    const updateFormData = (updatedData) => {
      Object.assign(formData, updatedData);
    };

    const currentStep = ref(1);
    const components = [StepOne, StepTwo, StepThree, StepFour];
    const currentComponent = computed(() => components[currentStep.value - 1]);

    const goToNextStep = async () => {
      try {
        isLoading.value = true; 
        if (currentStep.value === 3) {
          await createRegistration(formData);
          currentStep.value++;
        } else {
          if (currentStep.value < 3) currentStep.value++;
        }
      } catch (error) {
        console.error("Error al crear el registro:", error);

        const errorMessage =
          error.response?.data?.message || "Ha ocurrido un error inesperado.";
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          confirmButtonText: "Aceptar",
        });
      } finally {
        isLoading.value = false;
      }
    };

    const restartForm = () => {
      currentStep.value = 1;
    };

    return {
      formData,
      updateFormData,
      currentStep,
      currentComponent,
      goToNextStep,
      restartForm,
      isLoading,
    };
  },
};
</script>

<style scoped>
#socios .header {
  background: url("@/assets/images/bg_socios.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 325px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#socios .header .title {
  width: 100%;
  text-align: center;
}

#socios .header .title h2 {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 800;
  padding: 20px 0;
}

.asociarse {
  padding: 40px 15px;
  background: #90cbee;
  color: #1b4b80;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

#alta-socio .informacion {
  background: #ffffff;
  padding: 30px 10px 0 10px;
  text-align: center;
}

#alta-socio .informacion h3 {
  color: #00a0e1;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 1.8rem;
}

#alta-socio .form-control {
  background: transparent;
  border: none;
  color: #212529;
  margin-bottom: 15px;
  border-bottom: solid 1px #bfbfbf;
  border-radius: 0;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  #socios .header {
    height: 200px;
  }

  #socios .header .title h2 {
    font-size: 30px;
  }

  .asociarse {
    font-size: 1.2rem;
    padding: 30px 10px;
  }

  #alta-socio .informacion h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  #socios .header {
    height: 150px;
  }

  #socios .header .title h2 {
    font-size: 25px;
  }

  .asociarse {
    font-size: 1rem;
    padding: 20px 10px;
  }

  #alta-socio .informacion h3 {
    font-size: 1.2rem;
  }

  #alta-socio .form-control {
    font-size: 0.9rem;
    max-width: 100%;
  }
}
</style>

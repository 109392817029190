export const navbarData = {
  items: [
    { name: "Socios", to: "/socios", dropdown: "Servicios" },
    { name: "Asesoría Legal", to: "/asesoria-legal", dropdown: "Servicios" },
    { name: "Asesoría Impositiva", to: "/asesoria-impositiva", dropdown: "Servicios" },
    { name: "Asesoría Registral", to: "/asesoria-registral", dropdown: "Servicios" },
    { name: "Capacitación", to: "/capacitacion", dropdown: "Servicios" },
    { name: "Estadística y Mercado", to: "https://www.siomaa.com/", external: true, dropdown: "Servicios" },
    { name: "Autos", to: "/divisiones-autos", dropdown: "Divisiones" },
    { name: "Motovehículos", to: "/divisiones-motovehiculos", dropdown: "Divisiones" },
    { name: "Maquinaria Agrícola", to: "/divisiones-maquinaria-agricola", dropdown: "Divisiones" },
  ],
  fixedLinks: {
    initial: [{ name: "Sobre Nosotros", to: "/sobre-nosotros" }],
    final: [{ name: "Contacto", to: "/contacto" }],
  },
};

<template>
  <div
    v-if="isOpen"
    class="modal fade show"
    style="display: block; background-color: rgba(0, 0, 0, 0.5);"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content shadow-lg rounded-3 border-0">
        <div class="modal-header bg-dark text-light rounded-top py-3 px-4 border-bottom">
          <h5 class="modal-title">Cargos del Usuario</h5>
          <button
            type="button"
            class="btn-close btn-close-white"
            aria-label="Cerrar"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <p class="text-muted mb-3">
            A continuación, se muestran los cargos asignados al usuario:
          </p>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead class="table-light">
                <tr>
                  <th>Nombre</th>
                  <th>Área</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="position in positions"
                  :key="position?.position_name || 'default-key'"
                  class="align-middle"
                >
                  <td>{{ position?.position_name || 'N/A' }}</td>
                  <td>{{ position?.area_name || 'N/A' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    positions: {
      type: Array,
      required: true,
    },
  },
  emits: ["close"],
  setup(_,{ emit }) {
    const closeModal = () => {
      emit("close");
    };

    return {
      closeModal,
    };
  },
});
</script>

<style scoped>
.modal {
  z-index: 1050;
}
.modal-header {
  background-color: #007bff;
  color: #fff;
}
.modal-content {
  border-radius: 10px;
  overflow: hidden;
}
.table {
  margin-bottom: 0;
  border-radius: 10px;
}
.table-hover tbody tr:hover {
  background-color: #f8f9fa;
}
.btn-close-white {
  filter: invert(1);
}
</style>

<template>
  <div id="motos-informacion">
    <section class="header">
      <div class="title">
        <div class="container">
          <div class="col-12">
            <h3>
              <img :src="iconPath" alt="Guía de Patentamientos" height="55" />
              Guía de Patentamientos
            </h3>
          </div>
        </div>
      </div>
    </section>

    <section class="informacion">
      <div class="container">
        <div class="row">
          <div class="col-12 text-left">
            <h3>PATENTAMIENTO DE MOTOVEHÍCULOS 0KM</h3>
            <p>
              Cuando se adquiere un motovehículo 0 km es obligatorio
              subscribirse ante el registro automotor y esto puede hacerse de 2
              formas. Lo puede hacer el concesionario o lo puede hacer quien
              adquirió la unidad.
              <br /><br />
              Si la inscripción es encomendada al concesionario, el usuario
              podrá retirar la moto en el momento. El comerciante entregará al
              cliente un permiso de circulación de carácter temporario que
              tendrá una vigencia de SIETE (7) días corridos. En ese plazo, el
              concesionario inscribirá el motovehículo y entregará en ese lapso
              de tiempo, la placa patente y la cédula correspondiente a su
              comprador.
              <br /><br />
              En caso que el comprador decida patentar él, deberá concurrir al
              registro que le corresponda y llevar:
              <br /><br />
            </p>
            <h5>1. Documentación a presentar</h5>
            <p>
              • DNI, original y fotocopia.<br />
              • Constancia de CUIT/CUIL.<br />
              • Factura, original y copia firmada por la concesionaria.<br />
              • Formulario 12: es el formulario de verificación policial que es
              otorgado por el concesionario. Se debe presentar en el registro
              original y copia firmado por el titular o apoderado del
              comercio.<br />
              • Formulario 01: es otorgado completo por el concesionario.
              Presentar original y copia firmado por el titular o apoderado del
              comercio.<br />
              • Certificado de Importación si es importada o bien, certificado
              de fábrica si es industria nacional.<br />
              • Las firmas podrán ser certificadas en el registro.<br />
            </p>

            <h5>2. Procedimiento</h5>
            <p>
              Una vez presentada toda la documentación en el registro seccional
              correspondiente, se abonan los aranceles vigentes y a las 24/48hs
              se debe concurrir al registro y retirar la placa metálica y la
              cédula. Con esa documentación, ya es posible dirigirse al
              concesionario a colocar la chapa patente y retirar la unidad.
            </p>

            <h5>3. Aranceles</h5>
            <p>
              Además del pago de formularios que se hace al concesionario, en el
              registro se debe abonar el monto resultante de los siguientes
              ítems:
              <br /><br />
              • Inscripción Inicial (nacional o importado).<br />
              • Expedición cédula 0 km.<br />
              • Expedición cédula.<br />
              • Expedición placa metálica (bonificado).<br />
              • Alta impositiva.<br />
              • Formulario 13.<br /><br />
              Los costos de patentamiento de un motovehículo no son fijos sino
              que varían de acuerdo a la marca, el modelo, la cilindrada, el
              lugar de fabricación, la jurisdicción y el monto de la factura de
              compra.
            </p>

            <h5>4. Registro Seccional</h5>
            <p>
              El trámite se realiza en el Registro Seccional que corresponde al
              domicilio del adquiriente. Es posible chequear el registro en
              DNRPA.
            </p>

            <h5>¡IMPORTANTE!</h5>
            <p>
              » ANTES DE ADQUIRIR UN MOTOVEHÍCULO ES NECESARIO APRENDER A
              MANEJAR Y SACAR LA LICENCIA DE CONDUCIR CORRESPONDIENTE.<br /><br />
              » ES IMPORTANTE QUE CUENTES CON LA LICENCIA DE CONDUCIR
              HABILITANTE PARA LA CILINDRADA QUE ADQUIRISTE.<br /><br />
              » ACARA MOTOS SUGIERE QUE ASEGURES EL MOTOVEHÍCULO EN CUALQUIERA
              DE LAS COMPAÑÍAS DE SEGURO DISPONIBLES EN EL MERCADO.<br /><br />
              » ES UN CONSEJO DE ACARA CONDUCIR RESPONSABLEMENTE UTILIZANDO
              CASCO Y TODOS LOS ELEMENTOS DE PROTECCIÓN ADECUADOS.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <br /><br />
            <a @click.prevent="goBack" class="btn-volver">VOLVER</a>
            <br /><br />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "PatentamientoGuiaComponent",
  setup() {
    const router = useRouter();
    const iconPath = require("@/assets/images/icon_guia_patentamientos.png");
    const volverLink = ref("divisiones-motovehiculos.php");
    const goBack = () => {
      if (window.history.length > 1) {
        router.go(-1);
      } else {
        router.push("/");
      }
    };
     onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {
      goBack,
      iconPath,
      volverLink,
    };
  },
};
</script>

<style scoped>
#motos-informacion .header {
  background: #001f5b;
  height: 125px;
  position: relative;
  width: 100%;
}
#motos-informacion .header .title {
  position: absolute;
  bottom: 0;
  width: 100%;
}
#motos-informacion .header .title h3 {
  color: #ffffff;
  padding: 0 0 25px 0;
  text-transform: uppercase;
}
#motos-informacion .header img {
  margin-right: 25px;
}
#motos-informacion .informacion {
  background: #ffffff;
  padding: 45px 0 0 0;
  text-align: left;
}
#motos-informacion .informacion h3 {
  color: #00a0e1;
  margin-bottom: 25px;
  text-transform: uppercase;
}
#motos-informacion .informacion h5 {
  color: #00a0e1;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
}
#motos-informacion .btn-volver {
  color: #fff;
  background: #01145b;
  font-size: 1.2rem;
  font-weight: 800;
  padding: 10px 80px;
}
</style>

<template>
  <div class="col-12 col-md-4">
    <div
      class="box-servicio"
      :style="{ 
        backgroundColor: isHovered ? buttonColor : '#fff',
        maxWidth: '350px', 
        width: '100%'
      }"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <div class="img-container">
        <img :src="imageSrc" alt="" class="img-servicio" />
      </div>
      <img :src="iconSrc" alt="" class="icono" />
      <div class="titulo-y-boton" :class="{ hovered: isHovered }">
        <h3 :style="{ color: buttonColor }">{{ title }}</h3>
        <p>{{ description }}</p>
        <a
          v-if="linkHref"
          @click.prevent="navigateToLink(linkHref)"
          :style="{
            backgroundColor: buttonColor,
            color: '#fff',
            borderColor: isHovered ? '#fff' : buttonColor,
            border: '2px solid',
          }"
          href="#"
        >
          {{ linkText }}
        </a>
        <a
          v-else-if="pdfFile"
          :href="pdfFile"
          target="_self"
          :style="{
            backgroundColor: buttonColor,
            color: '#fff',
            borderColor: isHovered ? '#fff' : buttonColor,
            border: '2px solid',
          }"
        >
          {{ linkText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "CardComponent",
  props: {
    imageSrc: { type: String, required: true },
    iconSrc: { type: String, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
    linkHref: { type: String, default: null },
    pdfFile: { type: String, default: null },
    linkText: { type: String, default: "Ingresar" },
    buttonColor: { type: String, default: "#000000" },
  },
  data() {
    return { isHovered: false };
  },
  setup() {
    const router = useRouter();

    const navigateToLink = (link) => {
      const route = router.getRoutes().find((r) => r.path === `/${link}`);

      if (route) {
        router.push({ path: route.path });
      } else {
        window.open(link, "_blank");
      }
    };

    return { navigateToLink };
  },
};
</script>


<style scoped>
.box-servicio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  background: #fff;
  text-align: center;
  padding: 0 0 20px 0;
  min-height: 470px;
  height: 100%;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  border-radius: 25px;
}

.box-servicio:hover .img-servicio {
  transform: scale(1.05);
}

.box-servicio:hover .icono {
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.img-container {
  height: 200px;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.box-servicio .img-servicio {
  height: 100%;
  width: 100%;
  margin: 0 0 15px 0;
    border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.img-servicio {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icono {
  width: 90px;
  margin: -46px auto 0 auto;
  z-index: 1;
}

.titulo-y-boton {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
}

.titulo-y-boton.hovered h3,
.titulo-y-boton.hovered p {
  color: #fff !important;
}

.titulo-y-boton h3 {
  font-weight: 500;
  text-transform: uppercase;
  margin: 10px 0;
  font-size: 1rem;
  color: #2cbad2;
}

.titulo-y-boton p {
  color: #898989;
  font-weight: 300;
  font-size: 15px;
  margin: 0 0 10px 0;
}

.titulo-y-boton a {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 15px; 
  padding: 8px 20px;
  width: 70%;
  max-width: 200px;
  text-align: center;
  text-decoration: none;
  border: 2px solid;
  border-radius: 15px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  align-self: center;
}


.box-servicio {
  margin-left: 36px; 
}

@media (max-width: 768px) {
  .box-servicio {
    margin-left: 0; 
  }
}


</style>

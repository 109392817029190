import axios from '@/plugins/axios';

/**
 * Fetch all expiration calendars within a specified date range.
 * 
 * @param {string} [date=null] - Optional date string in 'YYYY-MM' format.
 * @returns {Promise<Object>} - The fetched expiration calendars data.
 */
export const fetchExpirationCalendars = async (date = null) => {
  try {
    const response = await axios.get('/expiration-calendars', {
      params: {
        date: date,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching expiration calendars:', error);
    throw error;
  }
};


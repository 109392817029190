<template>
  <section class="header">
    <div class="title">
      <div class="container">
        <div class="col-12">
          <h3>
            <img
              :src="require('@/assets/images/icon_comision_directiva_header.png')"
              height="25"
              alt="Icono Comisión Directiva"
            />
            Comisión Directiva
          </h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ComisionDirectivaHeader",
};
</script>

<style scoped>
.header {
  background: #00a0e1;
  height: 125px;
  position: relative;
  width: 100%;
}

.title h3 {
  color: #ffffff;
  padding: 25px 0 0 0;
  text-transform: uppercase;
}

.title {
  position: absolute;
  bottom: 0;
  width: 100%;
}

img {
  vertical-align: middle;
  border-style: none;
}
</style>

<template>
  <div class="pagination-container d-flex justify-content-end">
    <button
      @click="goToPage(1)"
      class="btn btn-sm btn-outline-secondary"
      :disabled="currentPage === 1"
    >
      Primera
    </button>
    <button
      @click="goToPage(currentPage - 1)"
      class="btn btn-sm btn-outline-secondary"
      :disabled="currentPage === 1"
    >
      Anterior
    </button>
    <button
      @click="goToPage(currentPage + 1)"
      class="btn btn-sm btn-outline-secondary"
      :disabled="!hasMorePages"
    >
      Siguiente
    </button>
    <button
      @click="goToPage(lastPage)"
      class="btn btn-sm btn-outline-secondary"
      :disabled="currentPage === lastPage"
    >
      Última
    </button>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
    hasMorePages: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["pageChange"],
  methods: {
    goToPage(page) {
      this.$emit("pageChange", page);
    },
  },
};
</script>

<style scoped>
.pagination-container {
  margin-top: 1rem;
}
</style>

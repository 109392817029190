<template>
  <div>
    <LoginModalComponent
      ref="loginModal"
      @openRecoverPassword="openRecoverPasswordModal"
    />
    <RecoverPasswordModalComponent ref="recoverModal" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import LoginModalComponent from "@/components/header/login/LoginModalComponent.vue";
import RecoverPasswordModalComponent from "@/components/header/login/RecoverPasswordModalComponent.vue";
import { eventBus } from "@/utils/eventBus.js";

export default {
  name: "AuthModalComponent",
  components: {
    LoginModalComponent,
    RecoverPasswordModalComponent,
  },
  setup(_, { expose }) {
    const loginModal = ref(null); 
    const recoverModal = ref(null);

    const showLoginModal = () => {
      if (loginModal.value) {
        loginModal.value.show();
      } else {
        console.error("LoginModalComponent no está disponible.");
      }
    };

    const openRecoverPasswordModal = () => {
      if (recoverModal.value) {
        recoverModal.value.show();
      } else {
        console.error("RecoverPasswordModalComponent no está disponible.");
      }
    };

    onMounted(async () => {
       eventBus.showLoginModal = showLoginModal;
    });

    expose({ showLoginModal, openRecoverPasswordModal });

    return {
      showLoginModal,
      openRecoverPasswordModal,
      loginModal,
      recoverModal,
    };
  },
};
</script>

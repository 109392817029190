import axios from '@/plugins/axios';

/**
 * Enviar datos del formulario de contacto al servidor.
 * @param {Object} contactData - Datos del formulario de contacto.
 * @param {string} contactData.business_name - Razón social.
 * @param {string} contactData.name - Nombre.
 * @param {string} contactData.lastname - Apellido.
 * @param {string} contactData.phone - Teléfono.
 * @param {string} contactData.email - Email.
 * @param {string} contactData.division - División.
 * @param {string} contactData.message - Mensaje.
 * @returns {Promise<Object>} - Respuesta del servidor con los datos creados.
 */
export const submitContactForm = async (contactData) => {
  try {
    const response = await axios.post('/contact-forms', contactData);
    return response.data.data;
  } catch (error) {
    console.error('Error submitting contact form:', error);
    throw error;
  }
};

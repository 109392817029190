import axios from '@/plugins/axios';

export const fetchComisionDirectiva  = async () => {
  try {
      const response = await axios.get('/directors', {
        params: {
            orderBy: "order",
            orderByMethod: "ASC",
        }
    });
      return response.data.data;
  } catch (error) {
      console.error('Error fetching director:', error);
      throw error;
  }
};
<template>
  <div class="calendar-container">
    <div class="calendar-content">
      <div class="selected-date-container">
        <div class="selected-date-content">
          <h3 class="selected-day">{{ selectedDay }}</h3>
          <h1 class="selected-date">{{ selectedDate }}</h1>
        </div>
      </div>

      <div class="calendar-wrapper">
        <div id="calendar-wrapper" :class="{ hidden: showEvents }">
          <div id="calendar"></div>
        </div>

        <div v-if="showEvents" class="event-list">
          <h3>Vencimientos</h3>
          <table class="table">
            <thead>
              <tr>
                <th>Impuesto</th>
                <th>Descripción</th>
                <th>CUIT</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(event, index) in filteredEvents"
                :key="index"
                :class="{ 'row-alt': index % 2 !== 0 }"
              >
                <td>{{ event.tax }}</td>
                <td>{{ event.description }}</td>
                <td>{{ event.last_cuit_digit }}</td>
              </tr>
            </tbody>
          </table>
          <button class="btn btn-primary" @click="resetCalendar">Volver</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import dayjs from "dayjs";
import "dayjs/locale/es";

dayjs.locale("es");

export default {
  name: "CalendarComponent",
  props: {
    events: {
      type: Array,
      required: true,
    },
    enabledDates: {
      type: Array,
      required: true,
    },
  },
  emits: ["fetch-date-events", "month-changed"],
  setup(props, { emit }) {
    const selectedDay = ref(dayjs().format("dddd"));
    const selectedDate = ref(dayjs().format("DD"));
    const filteredEvents = ref([]);
    const showEvents = ref(false);
    const selectedMonth = ref(dayjs().format("YYYY-MM"));

    let calendarInstance;

    const showEventsForDate = (date) => {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      emit("fetch-date-events", formattedDate);
      filteredEvents.value = props.events.filter((event) =>
        dayjs(event.date).isSame(date, "day")
      );
      showEvents.value = filteredEvents.value.length > 0;
      selectedDay.value = dayjs(date).format("dddd");
      selectedDate.value = dayjs(date).format("DD");
    };

    const resetCalendar = () => {
      showEvents.value = false;
      filteredEvents.value = [];
    };

    const initializeCalendar = () => {
      if (calendarInstance) {
        calendarInstance.destroy();
      }
      calendarInstance = flatpickr("#calendar", {
        locale: Spanish,
        inline: true,
        enable: props.enabledDates.map((date) => dayjs(date).toDate()),
        defaultDate: new Date(),
        onChange: (selectedDates) => {
          if (selectedDates[0]) {
            showEventsForDate(selectedDates[0]);
          }
        },
        onMonthChange: (selectedDates, dateStr, instance) => {
          const year = instance.currentYear;
          const month = instance.currentMonth + 1;
          selectedMonth.value = `${year}-${month.toString().padStart(2, "0")}`;
          const firstDayOfMonth = selectedMonth.value;
          emit("month-changed", firstDayOfMonth);
        },
      });
    };

    watch(
      () => props.enabledDates,
      (newDates, oldDates) => {
        if (JSON.stringify(newDates) !== JSON.stringify(oldDates)) {
          if (calendarInstance) {
            calendarInstance.set(
              "enable",
              newDates.map((date) => dayjs(date).toDate())
            );
          } else {
            initializeCalendar();
          }
        }
      }
    );

    onMounted(() => {
      initializeCalendar();
    });

    return {
      selectedDay,
      selectedDate,
      filteredEvents,
      showEvents,
      resetCalendar,
    };
  },
};

</script>

<style>

.flatpickr-day.today {
  background-color: #ffeb3b; 
  color: #000; 
  border-radius: 50%; 
}

.flatpickr-day.disabled {
  cursor: default; 
  opacity: 0.5;
}
.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  padding: 2rem;
}

.calendar-content {
  display: flex;
  align-items: stretch;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
}

.selected-date-container {
  background-color: #17a2b8;
  color: white;
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.selected-date-content {
  text-align: center;
}

.selected-day {
  font-size: 1.5rem;
  margin: 0;
  text-transform: uppercase;
}

.selected-date {
  font-size: 4rem;
  margin: 0;
}

.calendar-wrapper {
 min-height: 400px;
  display: flex;
  flex-direction: column;
  flex: 1;
      padding: 3rem;
}

#calendar-wrapper.hidden {
  display: none;
}

#calendar {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.event-list {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 2rem;
  background-color: white;
  text-align: center; 
      padding-left: 86px;
    padding-right: 86px;
    min-height: 200px; 
  max-height: 400px; 
  overflow-y: auto; 
      overflow-x: clip;
}

.event-list h3 {
  color: #00a0e1 !important;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.table {
  width: 100%;
  margin: 1rem 0;
  border-collapse: collapse;
  text-align: center;
  font-size: 1rem;
}

.table th {
  background-color: #f8f9fa;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1rem;
  color: #4d4d4d;
  border-bottom: 2px solid #ddd;
}

.table td {
  padding: 0.8rem;
  color: #333;
}

.table tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.table tr:hover {
  background-color: #e9ecef;
}

.row-alt {
  background-color: #f8f9fa;
}

.btn {
  margin-top: 1rem;
  background-color: #002856; 
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.btn:hover {
  background-color: #004080;
}

.event-list h3{
  color: #00a0e1 !important;
}

@media (max-width: 768px) {
  .calendar-container {
    flex-direction: column; 
    padding: 1rem;
  }

  .calendar-content {
    flex-direction: column;
    width: 100%; 
    box-shadow: none; 
  }

  .selected-date-container {
    width: 100%; 
    padding: 1rem; 
    text-align: center;
  }

  .calendar-wrapper {
    padding: 1rem;
  }

  .event-list {
    padding: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table {
    font-size: 0.9rem; 
  }

  .table th,
  .table td {
    padding: 0.5rem;
  }

  .btn {
    width: 100%;
    padding: 0.8rem;
  }
}

@media (max-width: 480px) {
  .selected-date-container {
    padding: 0.5rem; 
  }

  .selected-day {
    font-size: 1rem; 
  }

  .selected-date {
    font-size: 2.5rem; 
  }

  .table {
    font-size: 0.8rem; 
  }

  .table th,
  .table td {
    padding: 0.3rem; 
  }
}

</style>

import { reactive } from "vue";

export default function useContactValidations() {
  const errors = reactive({});

  const validateFields = (formData) => {
    Object.keys(errors).forEach((key) => delete errors[key]);

    if (!formData.businessName || formData.businessName.trim() === "") {
      errors.businessName = "El campo Razón Social es obligatorio.";
    }

    if (!formData.name || formData.name.trim() === "") {
      errors.name = "El campo Nombre es obligatorio.";
    }

    if (!formData.lastname || formData.lastname.trim() === "") {
      errors.lastname = "El campo Apellido es obligatorio.";
    }

    if (!formData.email || formData.email.trim() === "") {
      errors.email = "El campo Email es obligatorio.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "El formato del Email no es válido.";
    }

    if (!formData.phone || formData.phone.trim() === "") {
      errors.phone = "El campo Teléfono es obligatorio.";
    } else if (!/^\d+$/.test(formData.phone.trim())) {
      errors.phone = "El Teléfono solo debe contener números.";
    }

    if (!formData.division || formData.division.trim() === "") {
      errors.division = "Debe seleccionar una División.";
    }

    if (!formData.comment || formData.comment.trim().length < 5) {
      errors.comment = "El Comentario debe tener al menos 5 caracteres.";
    }

    return Object.keys(errors).length === 0;
  };

  const clearError = (field) => {
    if (errors[field]) {
      delete errors[field];
    }
  };

  return {
    errors,
    validateFields,
    clearError,
  };
}

<template>
  <div class="map-container">
    <div ref="chartdiv" class="chartdiv"></div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, onBeforeUnmount } from "vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_argentinaLow from "@amcharts/amcharts5-geodata/argentinaLow";

export default {
  name: "MapChartComponent",
  emits: ["province-selected"],
  setup(_, { emit }) {
    const chartdiv = ref(null);
    let root = null;

    const province = reactive({
      id: "AR-C",
      name: "Ciudad Autónoma de Buenos Aires",
      CNTRY_NAME: "Argentina",
      TYPE: "District",
    });

    const initializeMap = () => {
      root = am5.Root.new(chartdiv.value);

      root._logo.dispose();
      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(
        am5map.MapChart.new(root, {
          projection: am5map.geoMercator(),
        })
      );

      const polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_argentinaLow,
        })
      );

      polygonSeries.mapPolygons.template.setAll({
        tooltipText: "{name}",
        interactive: true,
      });

      polygonSeries.mapPolygons.template.states.create("hover", {
        fill: am5.color(0x9c27b0),
      });

      polygonSeries.mapPolygons.template.states.create("active", {
        fill: am5.color(0xffa726),
      });

      chart.set("zoomControl", am5map.ZoomControl.new(root, {}));

      polygonSeries.mapPolygons.template.events.on("click", (ev) => {
        const selectedData = ev.target.dataItem.dataContext || {};
        province.id = selectedData.id || "AR-C";
        province.name = selectedData.name || "Ciudad Autónoma de Buenos Aires";
        province.CNTRY_NAME = selectedData.CNTRY_NAME || "Argentina";
        province.TYPE = selectedData.TYPE || "District";


        emit("province-selected", { ...province });
      });
    };

    onMounted(() => {
      initializeMap();

      emit("province-selected", { ...province });
    });

    onBeforeUnmount(() => {
      if (root) {
        root.dispose();
      }
    });

    return {
      chartdiv,
      province,
    };
  },
};
</script>

<style scoped>
.map-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.chartdiv {
  width: 100%;
  height: 600px;
  max-width: 800px; 
}

@media screen and (max-width: 768px) {
  .chartdiv {
    height: 400px;
  }
}

@media screen and (max-width: 480px) {
  .chartdiv {
    height: 300px;
  }
}
</style>

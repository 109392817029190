const imagesContext = require.context("@/assets/images", true, /\.(png|jpe?g|svg)$/);

export function getImage(imageName, subdirectory = "") {
  try {
    const path = subdirectory
      ? `./${subdirectory}/${imageName}`
      : `./${imageName}`;
    
    const image = imagesContext(path);

    if (image) {
      return image;
    } else {
      console.error("Image not found:", path);
      return "";
    }
  } catch (e) {
    console.error("Error loading image:", imageName, e);
    return "";
  }
}




export function getMediaPath(fileName) {
  try {
    return require(`@/assets/videos/${fileName}`);
  } catch (e) {
    console.error('Media not found:', fileName);
    return '';
  }
}


export function getImageApi(imageName) {
  try {
    return imageName;
  } catch (e) {
    console.error('Image not found:', imageName);
    return '';
  }
}

export function getMediaPathApi(fileName) {
  try {
    return fileName;
  } catch (e) {
    console.error('Media not found:', fileName);
    return '';
  }
}
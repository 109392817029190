<template>
  <div v-if="loading" class="loading-container">
    <div class="spinner"></div>
    <p class="loading-text">Cargando Comisión Directiva...</p>
  </div>
  <div v-else-if="error">
    <p class="error-message">{{ error }}</p>
  </div>
  <div v-else-if="comisionDirectiva" id="comision-directiva">
    <ExecutiveCommitteeHeader />
    <OrganizationalChart :comisionDirectiva="comisionDirectiva" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { fetchComisionDirectiva } from "@/services/api/comisionDirectiva.js";
import ExecutiveCommitteeHeader from "@/views/comision-directiva/components/ExecutiveCommitteeHeader.vue";
import OrganizationalChart from "@/views/comision-directiva/components/OrganizationalChart.vue";

export default {
  name: "ComisionDirectivaView",
  components: {
    ExecutiveCommitteeHeader,
    OrganizationalChart,
  },
  setup() {
    const comisionDirectiva = ref(null);
    const loading = ref(true);
    const error = ref(null);

    const loadComisionDirectiva = async () => {
      try {
        const response = await fetchComisionDirectiva();
        if (response) {
          comisionDirectiva.value = response;
        } else {
          error.value = "Comisión Directiva no encontrada";
        }
      } catch (err) {
        error.value = "Error al cargar la Comisión Directiva";
        console.error(err);
      } finally {
        loading.value = false;
      }
    };

    onMounted(loadComisionDirectiva);

    return {
      loading,
      error,
      comisionDirectiva,
    };
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa; 
  text-align: center;
}

.spinner {
  border: 6px solid #f3f3f3; 
  border-top: 6px solid #007bff; 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #343a40; 
}

.error-message {
  color: #dc3545; 
  font-size: 1rem;
  font-weight: bold;
}
</style>

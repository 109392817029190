<template>
  <div>
    <section
      v-for="(buttonLinkGroup, groupIndex) in buttonLinks.filter(
        (group) => group && group[0]
      )"
      :key="groupIndex"
      :style="getBackgroundStyle(buttonLinkGroup[0])"
    >
      <div
        class="container custon-container"
        v-if="
          buttonLinkGroup[0].privacy !== 1 ||
          (buttonLinkGroup[0].privacy === 1 && isLoggedIn)
        "
      >
        <div class="row">
          <h3
            v-if="shouldDisplayTitle(buttonLinkGroup[0])"
            :class="getTitleClass(buttonLinkGroup[0])"
          >
            {{ buttonLinkGroup[0].title }}
          </h3>
          <h4
            v-if="
              buttonLinkGroup[0]?.type === 3 || buttonLinkGroup[0]?.type === 4
            "
            class="subtitleBanner"
          >
            {{ buttonLinkGroup[0].content }}
          </h4>
        </div>
        <div :class="getFlexContainerClass(buttonLinkGroup[0])">
          <div
            v-for="(buttonLink, index) in buttonLinkGroup"
            :key="index"
            class="flex-item text-center"
          >
            <template v-if="buttonLink.link !== null">
              <a
                :href="buttonLink.link"
                class="d-block"
                :style="{ cursor: getCursorStyle(buttonLinkGroup[0]) }"
                @click.prevent="
                  buttonLink.login === 1 && !isLoggedIn
                    ? openLoginModal()
                    : navigateToLink(buttonLink.link)
                "
              >
                <img
                  :src="buttonLink.image"
                  :class="getImageClass(buttonLinkGroup[0])"
                />
                <h5 :class="getNameClass(buttonLinkGroup[0], buttonLink)">
                  {{ buttonLink.name }}
                </h5>
                <p
                  class="custonContent"
                  v-if="
                    buttonLink.content &&
                    buttonLinkGroup[0]?.type !== 3 &&
                    buttonLinkGroup[0]?.type !== 4
                  "
                >
                  {{ buttonLink.content }}
                </p>
                <button
                  v-if="shouldDisplayButton(buttonLink)"
                  :class="getButtonClass(buttonLinkGroup[0])"
                >
                  {{ getButtonLabel(buttonLink) }}
                </button>
              </a>
            </template>
            <template v-else>
              <a
                href="#"
                @click.prevent="handleOpenModal(buttonLink)"
                class="d-block"
                :style="{ cursor: getCursorStyle(buttonLinkGroup[0]) }"
              >
                <img
                  :src="buttonLink.image"
                  :class="getImageClass(buttonLinkGroup[0])"
                />
                <h5 :class="getNameClass(buttonLinkGroup[0], buttonLink)">
                  {{ buttonLink.name }}
                </h5>
                <p
                  class="custonContent"
                  v-if="buttonLink.content && buttonLinkGroup[0]?.type !== 3"
                >
                  {{ buttonLink.content }}
                </p>
                <button
                  v-if="shouldDisplayButton(buttonLink)"
                  :class="getButtonClass(buttonLinkGroup[0])"
                >
                  {{ getButtonLabel(buttonLink) }}
                </button>
              </a>
            </template>
          </div>
        </div>
      </div>
    </section>

    <FileModal
      v-show="selectedButtonFiles"
      :buttonFiles="selectedButtonFiles"
      :modalTitle="modalTitle"
      @close="selectedButtonFiles = null"
    />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import FileModal from "./FileModal.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { eventBus } from "@/utils/eventBus.js";

export default {
  name: "ButtonLinkComponent",
  components: {
    FileModal,
  },
  props: {
    buttonLinks: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const authModal = ref(null);

    const navigateToLink = (link) => {
      const route = router.getRoutes().find((r) => r.path === `/${link}`);

      if (route) {
        router.push({ path: route.path });
      } else {
        window.open(link, "_blank");
      }
    };
    const selectedButtonFiles = ref(null);
    const modalTitle = ref("");

    const isLoggedIn = computed(() => store.getters.isAuthenticated);

    const openLoginModal = async () => {
      if (eventBus.showLoginModal) {
        eventBus.showLoginModal();
      } else {
        console.error("AuthModalComponent no ha registrado showLoginModal");
      }
    };

    const handleOpenModal = (buttonLink) => {
      if (buttonLink.login === 1 && !isLoggedIn.value) {
        openLoginModal();
        return;
      }
      if (buttonLink.button_files && buttonLink.button_files.length > 0) {
        selectedButtonFiles.value = buttonLink.button_files;
        modalTitle.value = buttonLink.name;
      } else if (buttonLink.file) {
        window.open(buttonLink.file, "_blank");
      } else {
        console.error("No se encontraron archivos para este enlace.");
      }
    };

    const getFlexContainerClass = (group) => {
      if (group.type === 3) {
        return "flex-container-banner";
      } else if (group.type === 2) {
        return "flex-container-three";
      } else {
        return "flex-container-button";
      }
    };

    const getBackgroundStyle = (group) => {
      if (!group) {
        console.warn("Group is undefined or null in getBackgroundStyle");
        return { background: "#ffffff" };
      }

      let defaultBackground;

      switch (group.type) {
        case 3:
          defaultBackground = "#101b5a";
          break;
        case 4:
          defaultBackground = "#FEFBF9";
          break;
        default:
          defaultBackground = "#ffffff";
      }

      return {
        background: group.background_color || defaultBackground,
      };
    };

    const shouldDisplayTitle = (group) => {
      return group?.title?.toLowerCase() !== "no title";
    };

    const getTitleClass = (group) => {
      if (group.type === 3) {
        return "titleBanner";
      } else if (group.type === 4) {
        return "titleBannerButton";
      } else {
        return "titleButtonLinks";
      }
    };

    const getCursorStyle = (group) => {
      return group?.type === 3 ? "unset" : "pointer";
    };

    const getImageClass = (group) => {
      return {
        imageBanner: group.type === 3,
        imageBannerWithButton: group.type === 4,
      };
    };

    const getNameClass = (group, buttonLink) => {
      return group.type === 3
        ? "nameBanner"
        : buttonLink.type === 2
        ? "link-type-2-h5"
        : "";
    };

    const shouldDisplayButton = (buttonLink) => {
      return (
        buttonLink.type === 2 ||
        buttonLink.type === 5 ||
        (buttonLink.type === 4 && buttonLink.link !== null)
      );
    };

    const getButtonClass = (group) => {
      return group.type === 4 ? "btn btn-banner mt-2" : "btn btn-custom mt-2";
    };

    const getButtonLabel = (buttonLink) => {
      return buttonLink.type === 4 ? "INSCRIBIRME" : "INGRESAR";
    };


    return {
      authModal,
      navigateToLink,
      openLoginModal,
      handleOpenModal,
      shouldDisplayTitle,
      getTitleClass,
      getCursorStyle,
      getImageClass,
      getNameClass,
      getBackgroundStyle,
      shouldDisplayButton,
      getButtonClass,
      getButtonLabel,
      getFlexContainerClass,
      selectedButtonFiles,
      modalTitle,
      isLoggedIn,
    };
  },
};
</script>


<style scoped>
a {
  background-color: transparent;
  text-decoration: none;
  display: block;
}

h5 {
  color: #00a0e1;
  text-transform: uppercase;
  margin-top: 15px;
}

.background_color-default {
  padding: 50px;
  background-color: white;
}

.btn-custom {
  background-color: #00999c;
  border-color: #00999c;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 0;
  padding: 5px 35px;
}

.btn-custom:hover {
  background-color: #007b7c;
  border-color: #007b7c;
  color: #e0e0e0;
}

.link-type-2-h5 {
  padding: 15px 0;
  font-weight: 400;
  color: #00a0e1;
  text-transform: uppercase;
}

.titleButtonLinks {
  text-transform: uppercase;
  color: #00a0e1;
  font-weight: 400;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.sectionBanner {
  background: #101b5a;
  color: #ffffff;
  text-align: center;
  padding: 25px;
}

.titleBanner {
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  color: #ffffff;
  text-transform: uppercase;
}

.titleBannerButton {
  color: #ffffff;
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
}

.subtitleBanner {
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 40px;
}

.imageBanner {
  height: 50px;
}

.imageBannerWithButton {
  height: 80px;
}

.nameBanner {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 800;
  color: #ffffff;
  text-transform: uppercase;
}

.flex-container-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  align-items: end;
}

.flex-container-banner {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: nowrap;
  align-items: end;
}

@media (max-width: 768px) {
  .flex-container-button .flex-item {
    flex: 0 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .flex-container-inline {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center !important;
  }
  .flex-container-inline .flex-item {
    flex: 0 1 100%;
    max-width: 100%;
  }

  .flex-container-button a {
    width: 85%;
  }
  .flex-item a {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .flex-container-banner {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex-container-banner .flex-item {
    flex: 0 1 100%;
    max-width: 100%;
  }
}

.flex-item {
  flex: 0 1 30%;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.custon-container {
  padding-top: 25px;
  max-width: 1140px;
}

.btn-banner {
  color: #0377bd;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  border: none;
  cursor: pointer;
}

.btn-banner:hover {
  color: #005a99;
}

.custonContent {
  color: black;
}

.flex-container-inline {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: end;
}

@media (max-width: 768px) {
  .flex-container-inline {
    flex-wrap: wrap;
  }
}

.flex-container-three {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 34px;
  align-items: end;
}

.flex-container-three .flex-item {
  flex: 1 1 calc(33.333% - 16px);
  max-width: calc(33.333% - 16px);
  box-sizing: border-box;
  margin-bottom: 54px;
}

@media (max-width: 768px) {
  .flex-container-three .flex-item {
    flex: 0 1 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
}
</style>

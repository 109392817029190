<template>
  <section class="novedades">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>Novedades</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <swiper-container
            v-if="isMobile"
            :slides-per-view="1"
            :navigation="true"
            :pagination="{ clickable: true }"
            :autoplay="{ delay: 3000, disableOnInteraction: false }"
            :loop="true"
            class="mySwiper"
          >
            <swiper-slide v-for="(novedad, index) in articles" :key="index">
              <NovedadCard
                :date="novedad.date"
                :imgSrc="novedad.image"
                :link="novedad.link"
                :title="novedad.title"
                :content="novedad.content"
                :id="novedad.id"
                :type="novedad.type"
                @view-more="handleViewMore"
              />
            </swiper-slide>
          </swiper-container>
          <div v-else class="carousel-novedades">
            <NovedadCard
              v-for="(novedad, index) in articles"
              :key="index"
              :date="novedad.date"
              :imgSrc="novedad.image"
              :link="novedad.link"
              :title="novedad.title"
              :content="novedad.content"
              :id="novedad.id"
              :type="novedad.type"
              @view-more="handleViewMore"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import NovedadCard from "./NovedadCard.vue";
import novedadesData from "@/services/api/novedades.js";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default defineComponent({
  name: "NovedadComponent",
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  components: {
    NovedadCard,
  },
  data() {
    return {
      novedades: novedadesData,
      isMobile: false,
    };
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    handleViewMore(articleId, slugTitle) {
      this.$router.push({ name: "novedades", params: { slugTitle, id: articleId } });
    },
  },
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

h1 {
  font-weight: 300;
  color: #06205c;
  text-align: center;
  font-size: 2.3rem;
  text-transform: uppercase;
  margin: 0 0 40px 0;
}

.novedades {
  font-family: 'Montserrat', sans-serif;
  background: #ebebeb;
  padding: 70px 0 100px 0;
}

.carousel-novedades {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.carousel-novedades .item-slick {
  flex: 1 1 calc(33.33% - 20px);
  margin: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  overflow: hidden;
  background-color: #fff;
}

.mySwiper {
  width: 100%;
  height: auto;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}

@media (max-width: 768px) {
  .carousel-novedades {
    flex-direction: column;
    align-items: center;
  }

  .carousel-novedades .item-slick {
    flex: 1 1 100%;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.8rem;
  }

  .novedades {
    padding: 50px 0 70px 0;
  }
}
</style>

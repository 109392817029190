<template>
  <div class="container stepCustom">
    <div class="row">
      <div class="col-12 text-left">
        <h3>1. INGRESÁ EL CUIT DE TU CONCESIONARIO</h3>
        <p>Para poder continuar necesitamos validar el CUIT.</p>
        <form>
          <input
            type="text"
            class="form-control input-custom"
            v-model="localFormData.cuit"
            placeholder="CUIT"
          />
        </form>
        <span v-if="errorMessage" class="error-message">{{
          errorMessage
        }}</span>
        <span v-if="successMessage" class="success-message">{{
          successMessage
        }}</span>
      </div>
    </div>
    <div class="row button-row">
      <div class="col-12 text-center">
        <button class="btn-continuar" @click.prevent="nextStep">
          CONTINUAR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { checkConcessionaire } from "@/services/api/registration";

export default {
  name: "StepOne",
  props: {
    formData: Object,
  },
  setup(props, { emit }) {
    const state = reactive({
      localFormData: { ...props.formData },
      errorMessage: "",
      successMessage: "",
    });

    const resetCuit = () => {
      state.localFormData.cuit = "";
      state.errorMessage = "";
      state.successMessage = "";
    };

    const nextStep = async () => {
      state.errorMessage = "";
      state.successMessage = "";

      if (!state.localFormData.cuit) {
        state.errorMessage = "Por favor, ingrese un CUIT válido.";
        return;
      }

      try {
        const concessionaire = await checkConcessionaire(
          state.localFormData.cuit
        );

        state.successMessage = `Concesionario encontrado: ${concessionaire.business_name}`;

        emit("update-form-data", {
          ...state.localFormData,
          concessionaire_id: concessionaire.id,
          concessionaire_name: concessionaire.business_name,
        });

        emit("next");
      } catch (error) {
        console.error("Error in checkConcessionaire:", error);
        state.errorMessage =
          error.response?.data?.message ||
          "Ocurrió un error al validar el CUIT.";
      }
    };

    onMounted(() => {
      resetCuit();
    });

    return {
      ...toRefs(state),
      nextStep,
    };
  },
};
</script>

<style scoped>
.stepCustom {
  background: #ffffff;
  padding: 30px 15px;
  text-align: center;
}

h3 {
  color: #00a0e1;
  margin-bottom: 15px;
  text-transform: uppercase;
  text-align: left;
  font-size: 1.5rem;
}

p {
  text-align: left;
  font-size: 1rem;
}

.button-row {
  padding: 20px 0 0;
}

.btn-continuar {
  color: #fff;
  background: #01145b;
  font-size: 1.1rem;
  font-weight: 800;
  padding: 10px 60px;
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
}

.input-custom {
  border: none;
  border-bottom: 1px solid #d3d3d3;
  border-radius: 0;
  width: 100%;
  font-size: 1rem;
  color: #888;
  padding: 10px 0;
  outline: none;
}

.input-custom::placeholder {
  color: #c0c0c0;
  font-size: 0.9rem;
}

.input-custom:focus {
  border-bottom: 1px solid #00a0e1;
  color: #000;
}

.error-message {
  color: #ff151b;
  font-size: 0.9rem;
  margin-top: 10px;
  display: block;
  text-align: left;
}

.success-message {
  color: #28a745;
  font-size: 0.9rem;
  margin-top: 10px;
  display: block;
  text-align: left;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.9rem;
  }

  .btn-continuar {
    font-size: 1rem;
    padding: 10px 40px;
  }
}

@media (max-width: 576px) {
  h3 {
    font-size: 1rem;
  }

  p {
    font-size: 0.8rem;
  }

  .btn-continuar {
    font-size: 0.9rem;
    padding: 8px 30px;
  }

  .input-custom {
    font-size: 0.9rem;
    padding: 8px 0;
  }

  .error-message,
  .success-message {
    font-size: 0.8rem;
  }
}
</style>

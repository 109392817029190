import axios from '@/plugins/axios';

/**
 * Fetch all registrations with optional status filter.
 * @param {Object} params Query parameters (e.g., { status: 1 }).
 */
export const fetchRegistrations = async (params = {}) => {
  try {
    const response = await axios.get('/registrations', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching registrations:', error);
    throw error;
  }
};

/**
 * Create a new registration.
 * @param {Object} registrationData Data to create the registration.
 */
export const createRegistration = async (registrationData) => {
  try {
    const response = await axios.post('/registrations', registrationData);
    return response.data.data;
  } catch (error) {
    console.error('Error creating registration:', error);
    throw error;
  }
};

/**
 * Fetch a single registration by ID.
 * @param {Number} id Registration ID.
 */
export const fetchRegistration = async (id) => {
  try {
    const response = await axios.get(`/registrations/${id}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching registration:', error);
    throw error;
  }
};

/**
 * Update a registration by ID.
 * @param {Number} id Registration ID.
 * @param {Object} updateData Data to update the registration.
 */
export const updateRegistration = async (id, updateData) => {
  try {
    const response = await axios.put(`/registrations/${id}`, updateData);
    return response.data.data;
  } catch (error) {
    console.error('Error updating registration:', error);
    throw error;
  }
};

/**
 * Approve a registration by ID.
 * @param {Number} id Registration ID.
 */
export const approveRegistration = async (id) => {
  try {
    const response = await axios.put(`/registrations/${id}/approve`);
    return response.data;
  } catch (error) {
    console.error('Error approving registration:', error);
    throw error;
  }
};

/**
 * Reject a registration by ID.
 * @param {Number} id Registration ID.
 */
export const rejectRegistration = async (id) => {
  try {
    const response = await axios.put(`/registrations/${id}/reject`);
    return response.data;
  } catch (error) {
    console.error('Error rejecting registration:', error);
    throw error;
  }
};

/**
 * Delete a registration by ID.
 * @param {Number} id Registration ID.
 */
export const deleteRegistration = async (id) => {
  try {
    const response = await axios.delete(`/registrations/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting registration:', error);
    throw error;
  }
};

/**
 * Check if a concessionaire exists by CUIT.
 * @param {String} cuit CUIT of the concessionaire.
 */
export const checkConcessionaire = async (cuit) => {
  try {
    const response = await axios.get('/registrations/check-concessionaire', {
      params: { cuit },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error checking concessionaire:', error);
    throw error;
  }
};

/**
 * Check if a user exists by DNI.
 * @param {String} dni DNI of the user.
 */
export const checkDni = async (dni) => {
  try {
    const response = await axios.get('/registrations/check-dni', {
      params: { dni },
    });
    return response.data;
  } catch (error) {
    console.error('Error checking DNI:', error);
    throw error;
  }
};

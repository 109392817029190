<template>
  <section class="organigrama">
    <div class="container">
      <OrganizationalChartSectionOne :committeeSection1="committeeSection1" />
      <OrganizationalChartSectionOther :committeeSection2="committeeSection2" />
    </div>
  </section>
</template>

<script>
import { defineComponent,  computed } from "vue";
import OrganizationalChartSectionOne from "./OrganizationalChartSectionOne.vue";
import OrganizationalChartSectionOther from "./OrganizationalChartSectionOther.vue";

export default defineComponent({
  name: "OrganizationalChartComponent",
  props: {
    comisionDirectiva: {
      type: Array,
      required: true,
    },
  },
  components: {
    OrganizationalChartSectionOne,
    OrganizationalChartSectionOther,
  },
  setup(props) {
    const committeeSection1 = computed(() =>
      props.comisionDirectiva.filter((member) => member.section === "1")
    );

    const committeeSection2 = computed(() =>
      props.comisionDirectiva.filter((member) => member.section === "2" || member.section === "3")
    );


    return {
      committeeSection1,
      committeeSection2,
    };
  },
});
</script>

<style scoped>
.organigrama {
  background: #ffffff;
  padding: 75px 0;
  text-align: center;
}
</style>
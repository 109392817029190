<template>
  <section class="sucursales">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <swiper-container
            :slides-per-view="1"
            :loop="true"
            :autoplay="{ delay: 3000, disableOnInteraction: false }"
            :speed="1000"
            class="sucursalSwiper"
          >
            <swiper-slide v-for="slide in sucursalSlides" :key="slide.id" class="text-center">
              <div class="image-group">
                <img
                  v-for="(image, imgIndex) in slide.images"
                  :key="imgIndex"
                  :src="getImage(image.src, 'regional')" 
                  :alt="image.alt"
                  class="sucursal-media"
                />
              </div>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref } from "vue";
import { sucursalSlides } from "@/services/api/sucursal.js";
import { getImage } from "@/utils/mediaUtils.js";
import "swiper/css";

export default defineComponent({
  name: "SucursalComponent",
  setup() {
    const slides = ref(sucursalSlides);

    return {
      sucursalSlides: slides,
      getImage,
    };
  },
});
</script>


<style scoped>
.sucursales {
  padding: 18px 0;
}

.image-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  flex-wrap: wrap;
}

.sucursal-media {
  width: 140px; /* Ancho fijo */
  height: 140px; /* Alto fijo */
  object-fit: cover; /* Ajusta la imagen para que llene el contenedor sin distorsión */
  border-radius: 10px; /* Opcional, redondea los bordes */
  display: block;
}
  .image-group {
    gap: 60px;
  }

    .sucursal-media {
    width: 140px;
  }
@media (max-width: 768px) {
 .sucursal-media {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 576px) {
    .sucursal-media {
    width: 100px;
    height: 100px;
  }
  .image-group {
    gap: 10px;
  }
}

.sucursalSwiper {
  width: 100%;
  height: auto;
  position: relative;
}
</style>
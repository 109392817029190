<template>
  <div class="container stepCustom">
    <div class="row">
      <div class="col-12 text-left">
        <h3>2. INGRESÁ TU DNI</h3>
        <p>
          Necesitamos validar tu DNI. Recordá que el administrador de tu
          concesionario es <strong>{{localFormData.concessionaire_name}}</strong>.
        </p>
        <form>
          <input
            type="text"
            class="form-control input-custom"
            v-model="localFormData.dni"
            placeholder="DNI"
            @input="validateDni"
          />
          <span v-if="errors.dni" class="text-danger">{{ errors.dni }}</span>
        </form>
      </div>
    </div>
    <div class="row button-row">
      <div class="col-12 text-center">
        <button
          class="btn-continuar"
          :disabled="loading || !isFormValid"
          @click.prevent="nextStep"
        >
          CONFIRMAR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, onMounted } from "vue";
import { checkDni } from "@/services/api/registration.js";

export default {
  name: "StepTwo",
  props: {
    formData: Object,
  },
  setup(props, { emit }) {
    const state = reactive({
      localFormData: { ...props.formData },
      loading: false,
      errors: {
        dni: "",
      },
    });

    const resetDni = () => {
      state.localFormData.dni = "";
      state.errors.dni = "";
    };

    const validateDni = () => {
      const dni = state.localFormData.dni;

      const sanitizedDni = dni.replace(/\D/g, "");

      if (sanitizedDni.length > 8) {
        state.localFormData.dni = sanitizedDni.slice(0, 8);
      } else {
        state.localFormData.dni = sanitizedDni;
      }

      if (sanitizedDni.length < 7 || sanitizedDni.length > 8) {
        state.errors.dni = "El DNI debe tener entre 7 y 8 números.";
      } else {
        state.errors.dni = "";
      }
    };

    const nextStep = async () => {
      if (!state.localFormData.dni) {
        state.errors.dni = "Por favor, ingrese un DNI válido.";
        return;
      }

      try {
        state.loading = true;
        await checkDni(state.localFormData.dni);
        state.errors.dni = "";
        emit("update-form-data", state.localFormData);
        emit("next");
      } catch (error) {
        console.error("Error checking DNI:", error);
        state.errors.dni =
          error.response?.data?.message ||
          "Ocurrió un error al validar el DNI.";
      } finally {
        state.loading = false;
      }
    };

    const isFormValid = computed(() => {
      return (
        !state.errors.dni &&
        state.localFormData.dni.length >= 7 &&
        state.localFormData.dni.length <= 8
      );
    });

    onMounted(() => {
      resetDni();
    });

    return {
      validateDni,
      ...toRefs(state),
      nextStep,
      isFormValid,
    };
  },
};
</script>

<style scoped>
.stepCustom {
  background: #ffffff;
  padding: 30px 15px;
  text-align: center;
}

h3 {
  color: #00a0e1;
  margin-bottom: 15px;
  text-transform: uppercase;
  text-align: left;
  font-size: 1.5rem;
}

p {
  text-align: left;
  font-size: 1rem;
  margin-bottom: 15px;
}

.button-row {
  padding-top: 20px;
}

.btn-continuar {
  color: #fff;
  background: #01145b;
  font-size: 1rem;
  font-weight: 800;
  padding: 10px 40px;
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
}

.input-custom {
  border: none;
  border-bottom: 1px solid #d3d3d3;
  border-radius: 0;
  width: 100%;
  font-size: 1rem;
  color: #888;
  padding: 10px 0;
  outline: none;
}

.input-custom::placeholder {
  color: #c0c0c0;
  font-size: 0.9rem;
}

.input-custom:focus {
  border-bottom: 1px solid #00a0e1;
  color: #000;
}

.text-danger {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 5px;
  display: block;
  text-align: left;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.9rem;
  }

  .btn-continuar {
    font-size: 0.9rem;
    padding: 10px 30px;
  }
}

@media (max-width: 576px) {
  h3 {
    font-size: 1rem;
  }

  p {
    font-size: 0.8rem;
  }

  .btn-continuar {
    font-size: 0.8rem;
    padding: 8px 20px;
  }

  .input-custom {
    font-size: 0.9rem;
    padding: 8px 0;
  }

  .text-danger {
    font-size: 0.75rem;
  }
}
</style>

<template>
  <section class="buscador-circulares">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 text-center">
              <h2 class="title">Circulares</h2>
              <h4 class="subtitle">Buscador</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <form @submit.prevent="() => handleFetchCirculars(1)">
                <div class="row g-3">
                  <div class="col-12">
                    <select
                      v-model="filters.circularTopicId"
                      class="form-control"
                    >
                      <option value="0">Todos los Temas</option>
                      <option
                        v-for="tema in temas"
                        :key="tema.id"
                        :value="tema.id"
                      >
                        {{ tema.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12">
                    <input
                      type="text"
                      v-model="filters.title"
                      class="form-control"
                      placeholder="Título"
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="text"
                      v-model="filters.number"
                      class="form-control"
                      placeholder="Número Circular"
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="date"
                      v-model="filters.dateFrom"
                      class="form-control"
                      placeholder="Fecha Desde"
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="date"
                      v-model="filters.dateTo"
                      class="form-control"
                      placeholder="Fecha Hasta"
                    />
                  </div>
                  <div class="col-12 text-center mt-4">
                    <button type="submit" class="btn-volver">BUSCAR</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="text-center mt-4">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Cargando...</span>
      </div>
    </div>

    <div v-if="circulares && !loading">
      <CircularResults
        :circulares="circulares"
        @fetchCirculars="handleFetchCirculars"
      />
    </div>

    <div v-else class="no-results-container text-center mt-4">
      <i class="fas fa-search-minus no-results-icon"></i>
      <p class="no-results-message">
        No se encontraron resultados para su búsqueda.
      </p>
    </div>
  </section>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { fetchCircularTopics, fetchCirculars } from "@/services/api/circulares";
import CircularResults from "./CircularResults.vue";

export default {
  name: "CircularSearch",
  components: { CircularResults },
  setup() {
    const filters = reactive({
      circularTopicId: "0",
      title: "",
      number: "",
      dateFrom: "",
      dateTo: "",
      perPage: 10,
      orderBy: "date",
      orderByMethod: "desc",
    });

    const temas = ref([]);
    const circulares = ref(null);

    const loading = ref(false);

    const getNonEmptyFilters = () => {
      const validFilters = {};
      Object.entries(filters).forEach(([key, value]) => {
        if (value && value !== "0") validFilters[key] = value;
      });
      return validFilters;
    };

    const handleFetchCirculars = async (page) => {
      loading.value = true;
      try {
        const params = {
          ...getNonEmptyFilters(),
          page,
          perPage: filters.perPage,
          orderByMethod: filters.orderByMethod,
        };
        const response = await fetchCirculars(params);
        circulares.value = response || {
          data: [],
          meta: { current_page: 1, last_page: 1 },
        };
      } catch (error) {
        console.error("Error al buscar circulares:", error);
        circulares.value = {
          data: [],
          meta: { current_page: 1, last_page: 1 },
        };
      } finally {
        loading.value = false;
      }
    };

    const loadCircularTopics = async () => {
      try {
        const response = await fetchCircularTopics();
        temas.value = (response.data || []).map((topic) => ({
          id: topic.id,
          nombre: topic.name,
        }));
      } catch (error) {
        console.error("Error al cargar los temas:", error);
      }
    };

    onMounted(() => {
      loadCircularTopics();
    });

    return {
      filters,
      temas,
      circulares,
      loading,
      handleFetchCirculars,
    };
  },
};
</script>

<style scoped>
.buscador-circulares {
  padding: 25px 0;
}

.buscador-circulares .title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #01145b;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.buscador-circulares .subtitle {
  font-size: 1.5rem;
  color: #757575;
  margin-bottom: 20px;
}

.buscador-circulares form .form-control {
  background: transparent;
  border: none;
  color: #757575;
  margin-bottom: 15px;
  border-bottom: solid 1px #757575;
  border-radius: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.buscador-circulares .btn-volver {
  color: #fff;
  background: #01145b;
  font-size: 1.2rem;
  font-weight: 800;
  padding: 10px 80px;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.spinner-border .visually-hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .buscador-circulares .title {
    font-size: 2rem;
  }

  .buscador-circulares .subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .buscador-circulares .title {
    font-size: 1.8rem;
  }

  .buscador-circulares .subtitle {
    font-size: 1rem;
  }
}

.no-results-container {
  padding: 20px;
  border: 2px dashed #757575;
  border-radius: 10px;
  background-color: #f9f9f9;
  color: #757575;
}

.no-results-icon {
  font-size: 3rem;
  color: #01145b;
  margin-bottom: 10px;
}

.no-results-message {
  font-size: 1.2rem;
  font-weight: 500;
  color: #555;
  margin-top: 0;
}

@media (max-width: 768px) {
  .no-results-icon {
    font-size: 2.5rem;
  }

  .no-results-message {
    font-size: 1rem;
  }
}
</style>

import DivisionesAutosPrivadoView from "@/views/divisiones/privado/DivisionesAutosPrivado.vue";
import DivisionesMaquinariaAgricolaPrivadoView from "@/views/divisiones/privado/DivisionesMaquinariaAgricolaPrivadoView.vue";
import DivisionesMotovehiculoPrivadoView from "@/views/divisiones/privado/DivisionesMotovehiculoPrivadoView.vue";
import SociosViewPrivado from "@/views/servicios/privado/SociosPrivadoView.vue";
import AsesoriaLegalPrivado from "@/views/servicios/privado/AsesoriaLegalPrivadoView.vue";
import AsesoriaImpositivaPrivado from "@/views/servicios/privado/AsesoriaImpositivaPrivadoView.vue";
import AsesoriaRegistralPrivado from "@/views/servicios/privado/AsesoriaRegistralPrivadoView.vue";
import CapacitacionViewPrivado from "@/views/servicios/privado/CapacitacionPrivadoView.vue";

export const privateRoutes = [
  {
    path: "/divisiones-autos-privado",
    name: "divisiones-autos-privado",
    component: DivisionesAutosPrivadoView,
  },
  {
    path: "/divisiones-motovehiculos-privado",
    name: "divisiones-motovehiculos-privado",
    component: DivisionesMotovehiculoPrivadoView,
  },
  {
    path: "/divisiones-maquinaria-agricola-privado",
    name: "divisiones-maquinaria-agricola-privado",
    component: DivisionesMaquinariaAgricolaPrivadoView,
  },
  {
    path: "/socios-privado",
    name: "socios-privado",
    component: SociosViewPrivado,
  },
  {
    path: "/asesoria-legal-privado",
    name: "Asesoria legal privado",
    component: AsesoriaLegalPrivado,
  },
  {
    path: "/asesoria-impositiva-privado",
    name: "Asesoria impositiva privado",
    component: AsesoriaImpositivaPrivado,
  },
  {
    path: "/asesoria-registral-privado",
    name: "Asesoria registral privado",
    component: AsesoriaRegistralPrivado,
  },
  {
    path: "/capacitacion-privado",
    name: "capacitacion privado",
    component: CapacitacionViewPrivado,
  },
];

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="step-subtitle">SELECCIONA LA MARCA DE TU VEHICULO</h3>
      </div>
    </div>

    <div v-if="isLoading" class="loading-indicator">
      Cargando marcas...
    </div>

    <div v-else class="row">
      <div
        v-for="brand in brands.data"
        :key="brand.id"
        class="col-sm-3"
      >
        <a @click.prevent="selectBrand(brand)" class="link-selector">
          {{ brand.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";

export default {
  name: "VehicleBrandStep",
  props: {
    brands: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const currentStep = ref(3);
    const isLoading = ref(true);

    watchEffect(() => {
      if (props.brands && props.brands.data && props.brands.data.length > 0) {
        isLoading.value = false;
      }
    });

    const selectBrand = (brand) => {
      emit('updateStepProgress', { step: currentStep.value });
      emit('brandSelected', { brand: brand });
    };

    return {
      selectBrand,
      isLoading,
    };
  },
};
</script>

<style scoped>
.step-subtitle {
  color: #2cbad2;
  margin-bottom: 40px;
  margin-top: 40px;
}

.link-selector {
  padding: 10px;
  display: block;
  text-align: center;
  color: #2cbad2;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.link-selector:hover {
  color: #00a0e1;
}

.loading-indicator {
  text-align: center;
  font-size: 18px;
  color: #2cbad2;
}
</style>

import axios from '@/plugins/axios';

export const fetchArticle = async (id) => {
  try {
      const response = await axios.get(`/articles/${id}`);
      return response.data.data;
  } catch (error) {
      console.error('Error fetching article:', error);
      throw error;
  }
};

import axios from '@/plugins/axios';

export const fetchPositions = async (params = {}) => {
  try {
    const response = await axios.get('/positions', {
      params,
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching positions:', error);
    throw error;
  }
};

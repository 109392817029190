<template>
  <section class="telepase">
    <div class="container h-100 d-flex justify-content-center align-items-end">
      <div class="row">
        <div class="botones">
          <button
            class="btn btn-light"
            @click="handleAction('https://telepase.com.ar/register')"
          >
            Registrar Cliente en AUSA
          </button>
          <button
            class="btn btn-light"
            @click="handleAction('https://telepase.com.ar/login')"
          >
            Asociar Pase al Dominio
          </button>
          <a
            v-if="isAuthenticated"
            href="https://www.acara.org.ar/files/telepase/Video%20Telepase.mp4"
            target="_blank"
          >
            <button class="btn btn-light">Ver Video</button>
          </a>
          <a
            v-if="isAuthenticated"
            href="https://www.acara.org.ar/files/telepase/ACARA%20Telepase.pdf"
            target="_blank"
          >
            <button class="btn btn-light">Ver Instructivo</button>
          </a>
        </div>
      </div>
    </div>
    <LoginModalComponent />
  </section>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import LoginModalComponent from "@/components/header/login/LoginModalComponent.vue";
import * as bootstrap from "bootstrap";

const store = useStore();
const isAuthenticated = computed(() => !!store.state.user);

const handleAction = (url) => {
  if (isAuthenticated.value) {
    // Si está autenticado, redirige a la URL
    window.open(url, "_blank");
  } else {
    // Si no está autenticado, abre el modal
    const modalElement = document.getElementById("ingresarModal");
    if (modalElement) {
      const loginModal = new bootstrap.Modal(modalElement);
      loginModal.show();
    } else {
      console.error("No se encontró el elemento modal.");
    }
  }
};
</script>

<style scoped>
.telepase {
  background: url('@/assets/images/telepase-acara-ausa.jpg') no-repeat center;
  background-size: cover;
  background-color: #435b39;
  height: 587px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.container {
  height: 100%; 
}

.botones {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.btn {
  color: #435b39;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  background-color: aliceblue;
}

@media (max-width: 768px) {
  .telepase {
    height: 300px;
  }

  .botones {
    gap: 10px; 
    margin-bottom: 20px;
  }

  .btn {
     font-size: 6px;
    padding: 8px 20px; 
    flex: 1 1 auto; 
    text-align: center; 
    background-color: aliceblue;
  }
}
</style>

<template>
  <div class="price-style"> 
    <section class="header">
      <div class="title">
        <div class="container">
          <div class="col-12">
            <h3 class="header-title">
              <img :src="iconSrc" height="25" />
              Guía Oficial de Precios
              <span v-if="tipo"> | {{ tipo }}</span>
            </h3>
          </div>
        </div>
      </div>
    </section>

    <section class="guia">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p>
              La Guía oficial de precios es relevada permanentemente por la
              Comisión de Valuación de Vehículos Usados de ACARA.
            </p>
            <p>
              Esta Comisión acuerda las diferentes valorizaciones para cada
              marca, modelo y año, en base al desenvolvimiento comercial
              observado en cada línea o seguimiento durante el último periodo
              mensual.
            </p>
          </div>
        </div>
      </div>
    </section>

    <StepPrecios />
    
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import StepPrecios from "@/components/precios/PreciosComponent.vue";

export default {
  name: "GuiaOficialDePrecios",
  components: {
    StepPrecios,
  },
  setup() {
    const route = useRoute();
    const tipo = ref("");

    const iconSrc = require("@/assets/images/icon_header_guia_oficial_de_precios.png");

    onMounted(() => {
      tipo.value = route.query.tipo || "";
    });

    return {
      iconSrc,
      tipo,
    };
  },
};
</script>

<style scoped>
.header {
  background: #00a0e1;
  height: 125px;
  position: relative;
  width: 100%;
}

.header-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #ffffff;
  padding: 25px 0 0 0;
  text-transform: uppercase;
}

.guia {
  display: block;
  padding: 60px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.price-style{
      margin-bottom: 10%
}
</style>

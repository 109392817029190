export const sucursalSlides = [
  {
    id: 1,
    region: "Buenos Aires - Parte 1",
    images: [
      { src: "BsAs - Atlantico.png", alt: "BsAs - Atlántico" },
      { src: "BsAs - Centro.png", alt: "BsAs - Centro" },
      { src: "BsAs - GBA Sur.png", alt: "BsAs - GBA Sur" },
      { src: "BsAs - La Plata.png", alt: "BsAs - La Plata" },
    ],
  },
  {
    id: 2,
    region: "Buenos Aires - Parte 2",
    images: [
      { src: "BsAs - Zona Norte.png", alt: "BsAs - Zona Norte" },
      { src: "BsAs - Zona Oeste.png", alt: "BsAs - Zona Oeste" },
      { src: "BsAs - Zona Sur.png", alt: "BsAs - Zona Sur" },
      { src: "Capital Federal.png", alt: "Capital Federal" },
    ],
  },
  {
    id: 4,
    region: "Centro",
    images: [
      { src: "Cordoba.png", alt: "Córdoba" },
      { src: "Santa Fe.png", alt: "Santa Fe" },
      { src: "Entre Rios.png", alt: "Entre Ríos" },
      { src: "La Pampa.png", alt: "La Pampa" },
    ],
  },
  {
    id: 5,
    region: "Cuyo",
    images: [
      { src: "Cuyo - Mendoza.png", alt: "Cuyo - Mendoza" },
      { src: "CUYO - San Juan.png", alt: "Cuyo - San Juan" },
      { src: "CUYO - San Luis.png", alt: "Cuyo - San Luis" },
      { src: "La Rioja.png", alt: "La Rioja" },
    ],
  },
  {
    id: 6,
    region: "NEA",
    images: [
      { src: "NEA - Chaco_OK.png", alt: "NEA - Chaco" },
      { src: "NEA - Misiones.png", alt: "NEA - Misiones" },
      { src: "Corrientes.png", alt: "Corrientes" },
      { src: "Formosa.png", alt: "Formosa" },
    ],
  },
  {
    id: 7,
    region: "NOA",
    images: [
      { src: "NOA - Catamarca.png", alt: "NOA - Catamarca" },
      { src: "NOA - Salta.png", alt: "NOA - Salta" },
      { src: "NOA - Santiago del Estero.png", alt: "NOA - Santiago del Estero" },
      { src: "NOA - Tucuman.png", alt: "NOA - Tucumán" },
    ],
  },
  {
    id: 8,
    region: "Patagonia",
    images: [
      { src: "Patagonia - Chubut.png", alt: "Patagonia - Chubut" },
      { src: "Patagonia - Rio negro.png", alt: "Patagonia - Río Negro" },
      { src: "Patagonia - Santa cruz.png", alt: "Patagonia - Santa Cruz" },
      { src: "Patagonia - Tierra del Fuego.png", alt: "Patagonia - Tierra del Fuego" },
      { src: "COMAHUE - Neuquen.png", alt: "Comahue - Neuquén" },
    ],
  },
];

<template>
  <section class="contacto">
    <div class="container">
      <div class="row text-center text-md-left">
        <div class="col-12 col-md-4 mb-4 mb-md-0">
          <img :src="getImage('icon_footer_phone.png')" alt="Phone" class="mb-2 contact-image" />
          <p class="contact-text">
            <strong>Contactate con nosotros</strong><br />
            {{ getPhone() }}
          </p>
        </div>
        <div class="col-12 col-md-4 mb-4 mb-md-0">
          <img :src="getImage('icon_footer_envelope.png')" alt="Envelope" class="mb-2 contact-image" />
          <p class="contact-text">
            <strong>Envianos tus consultas a</strong><br />
            <a :href="'mailto:' + getEmail()" style="color: #fff;">
              {{ getEmail() }}
            </a>
          </p>
        </div>
        <div class="col-12 col-md-4 mb-4 mb-md-0">
          <img :src="getImage('icon_footer_map.png')" alt="Map" class="mb-2 contact-image" />
          <p class="contact-text">
            <strong>Encontranos</strong><br />
            estés dónde estés
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getImage } from '@/utils/mediaUtils.js';

export default {
  name: "ContactoComponent",
  props: {
    footerData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getImage,
    getPhone() {
      return this.footerData.find(item => item.phone)?.phone || '';
    },
    getEmail() {
      return this.footerData.find(item => item.email)?.email || '';
    },
  },
};
</script>

<style scoped>

* {
    font-family: 'Inter', sans-serif;
}

.contacto {
  background: #0177bd;
  color: #fff;
  padding: 40px 0 34px 0;
}

.contacto img {
  height: 50px;
  vertical-align: top;
  font-weight: 100;
      margin-right: 15px;
}

.contacto p {
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 100;
  position: relative;
  margin-top: 6px;
  text-align: left;
}

.contact-image {
  height: 40px;
}

.contact-text {
  font-size: 0.8rem;
}

@media (max-width: 576px) {
  .contacto img {
    height: 40px;
  }

  .contacto p {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}
</style>

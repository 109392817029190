<template>
  <div class="container paso-4">
    <div class="row header-row">
      <div class="col-12 text-left">
        <h3>Información Guardada</h3>
      </div>
    </div>
    <div class="row message-row">
      <div class="col-12 text-center">
        <h2>¡Gracias por registrarte!</h2>
        <h4>
          Si no tenías una cuenta, espera a que el administrador la habilite.
          Recibirás un correo electrónico una vez que esté listo.
        </h4>
      </div>
    </div>
    <div class="row button-row">
      <div class="col-12 text-center">
        <button class="btn-continuar" @click="restart">CONTINUAR</button>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "StepFour",
  methods: {
    restart() {
      this.$emit("restart");
    },
  },
};
</script>

<style scoped>
.paso-4 {
  background: #f9f9f9;
  padding: 40px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 20px auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.header-row h3 {
  color: #00a0e1;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.message-row h2 {
  color: #4caf50;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.message-row h4 {
  color: #555;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8;
}

.button-row {
  margin-top: 30px;
}

.btn-continuar {
  background: #01145b;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 12px 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-continuar:hover {
  background: #00a0e1;
  color: #fff;
  transform: translateY(-2px);
}

/* Responsividad */
@media (max-width: 768px) {
  .paso-4 {
    padding: 30px 15px;
    margin: 15px 0;
  }

  .header-row h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .message-row h2 {
    font-size: 1.6rem;
  }

  .message-row h4 {
    font-size: 1rem;
    line-height: 1.5;
  }

  .btn-continuar {
    font-size: 1rem;
    padding: 10px 30px;
  }
}

@media (max-width: 576px) {
  .header-row h3 {
    font-size: 1.3rem;
  }

  .message-row h2 {
    font-size: 1.4rem;
  }

  .message-row h4 {
    font-size: 0.9rem;
  }

  .btn-continuar {
    font-size: 0.9rem;
    padding: 8px 20px;
  }
}
</style>

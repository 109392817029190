<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="step-subtitle">Información del Vehículo</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div v-if="isLoading" class="loading-indicator">
          Cargando información del vehículo...
        </div>
        <div v-else>
          <div v-if="sanitizedHtml" v-html="sanitizedHtml"></div>
          <p v-else>No hay información disponible.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import DOMPurify from 'dompurify';

export default {
  name: 'VehiclePdfStep',
  props: {
    vehiculeHtml: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const sanitizedHtml = ref('');

    watch(
      () => props.vehiculeHtml,
      (newHtml) => {
        if (newHtml) {
          sanitizedHtml.value = DOMPurify.sanitize(newHtml);
        } else {
          sanitizedHtml.value = '';
        }
        isLoading.value = false;
      },
      { immediate: true } 
    );

    return {
      isLoading,
      sanitizedHtml,
    };
  },
};
</script>

<style scoped>
.step-subtitle {
  color: #2cbad2;
  margin: 40px 0;
}

.loading-indicator {
  text-align: center;
  font-size: 18px;
  color: #2cbad2;
}

.download-pdf a {
  color: #007bff !important;
  text-decoration: none !important;
  cursor: pointer;
}

.download-pdf {
  margin-top: 20px;
}

</style>

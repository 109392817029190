<template>
  <div class="usuarios-view">
    <header class="header">
      <h1>ADMINISTRADOR</h1>
    </header>
    <div class="table-container">
      <div class="table-header">
        <h2>Solicitudes de Acceso</h2>
      </div>
      <div class="mb-3">
        <input
          type="text"
          v-model="searchTerm"
          class="form-control"
          placeholder="Buscar..."
        />
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>DNI</th>
              <th>Correo Electrónico</th>
              <th>Concesionaria</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="registration in registrations" :key="registration.id">
              <td>{{ registration.name }}</td>
              <td>{{ registration.lastname }}</td>
              <td>{{ registration.dni }}</td>
              <td>{{ registration.email }}</td>
              <td>{{ registration.concessionaire.business_name }}</td>
              <td class="text-center">
                <button
                  @click="viewMoreRegistration(registration)"
                  class="btn btn-sm btn-outline-primary action-button"
                  :disabled="isProcessing"
                >
                  <i class="fas fa-eye"></i> Ver más
                </button>

                <button
                  @click="confirmApprove(registration.id)"
                  class="btn btn-sm btn-success action-button mx-1"
                  :disabled="isProcessing"
                >
                  <span v-if="isProcessing">
                    <i class="fas fa-spinner fa-spin"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-check-circle"></i> Aceptar
                  </span>
                </button>

                <button
                  @click="confirmReject(registration.id)"
                  class="btn btn-sm btn-danger action-button"
                  :disabled="isProcessing"
                >
                  <span v-if="isProcessing">
                    <i class="fas fa-spinner fa-spin"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-times-circle"></i> Rechazar
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="registrations.length === 0" class="no-results">
        No se encontraron solicitudes de Acceso.
      </div>

      <PaginationComponent
        :currentPage="currentPage"
        :lastPage="lastPage"
        :hasMorePages="hasMorePages"
        @pageChange="loadRegistrations"
      />
    </div>

    <RegistrationModal
      v-if="isRegistrationModalOpen"
      :isOpen="isRegistrationModalOpen"
      :registration="selectedRegistration"
      @close="isRegistrationModalOpen = false"
      @open-positions-modal="openPositionsModal"
      @approve="confirmApprove"
      @reject="confirmReject"
    />

    <PositionsModal
      v-if="isPositionsModalOpen"
      :isOpen="isPositionsModalOpen"
      :positions="selectedPositions"
      @close="closePositionsModal"
    />
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";

import { fetchRegistrations } from "@/services/api/registration.js";
import usePagination from "@/composables/usePagination";
import useModals from "@/composables/useRegistrationAndPositionModals";
import useRegistrationActions from "@/composables/useRegistrationActions";

import PaginationComponent from "@/components/common/PaginationComponent.vue";
import RegistrationModal from "./modals/RegistrationModal.vue";
import PositionsModal from "./modals/PositionsModal.vue";

export default {
  name: "AdministradorUsuariosView",
  components: {
    PaginationComponent,
    RegistrationModal,
    PositionsModal,
  },
  setup() {
    const store = useStore();
    const concessionaire_ids = ref([]);

    const selectedConcessionaire = store.getters.getSelectedConcessionaire;

    if (selectedConcessionaire && selectedConcessionaire.id) {
      concessionaire_ids.value.push(selectedConcessionaire.id);
    }


    // Modales
    const {
      isRegistrationModalOpen,
      selectedRegistration,
      viewMoreRegistration,
      closeRegistrationModal,
      isPositionsModalOpen,
      selectedPositions,
      openPositionsModal,
      closePositionsModal,
    } = useModals();

    // Paginación
    const searchTerm = ref("");
    const {
      currentPage,
      lastPage,
      hasMorePages,
      orderByMethod,
      orderBy,
      perPage,
      items: registrations,
      loadItems: loadRegistrations,
    } = usePagination(fetchRegistrations);

    const { isProcessing, confirmApprove, confirmReject } =
      useRegistrationActions(() =>
        loadRegistrations(currentPage.value, { search: searchTerm.value })
      );

    watch([searchTerm, orderBy, orderByMethod, perPage], () => {
      loadRegistrations(1, {
        search: searchTerm.value,
        concessionaire_ids: concessionaire_ids.value,
      });
    });

    // Carga inicial
    onMounted(() => {
      loadRegistrations(1, {
        search: searchTerm.value,
        concessionaire_ids: concessionaire_ids.value,
      });
    });

    return {
      // Modales
      isRegistrationModalOpen,
      selectedRegistration,
      viewMoreRegistration,
      closeRegistrationModal,
      isPositionsModalOpen,
      selectedPositions,
      openPositionsModal,
      closePositionsModal,

      // Paginación
      searchTerm,
      registrations,
      currentPage,
      lastPage,
      hasMorePages,
      loadRegistrations,

      // Acciones
      isProcessing,
      confirmApprove,
      confirmReject,
    };
  },
};
</script>

<style scoped>
.usuarios-view {
  padding: 20px;
  font-family: "Montserrat", sans-serif;
}

.header {
  background-color: #001f5b;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
}

.header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.header h2 {
  margin: 5px 0 0 0;
  font-size: 16px;
  font-weight: normal;
}

.table-container {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 6rem;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-header h2 {
  font-size: 20px;
  font-weight: bold;
  color: #001f5b;
}

.nuevo-btn {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nuevo-btn:hover {
  background-color: #0056b3;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: left;
  font-size: 14px;
}

.table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  justify-content: center;
  vertical-align: middle;
}

.options {
  display: flex;
  gap: 10px;
}

.options button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: blue;
}

.options button .fas {
  font-size: 14px;
  color: #001f5b;
}

.options button.btn-edit .fas {
  color: #007bff;
}

.options button.btn-reset .fas {
  color: #ffc107;
}

.options button.btn-delete .fas {
  color: #dc3545;
}

.no-results {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-top: 20px;
}

.table th {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

@media (max-width: 768px) {
  .table-container {
    padding: 2rem;
  }

  .table th,
  .table td {
    font-size: 12px;
    padding: 5px; 
    text-align: center;
  }

  .table th {
    white-space: nowrap; 
  }

  .action-button {
    font-size: 10px; 
    padding: 5px 8px;
  }

  .table-responsive {
    overflow-x: auto; 
  }

  .header {
    padding: 10px;
    font-size: 18px;
  }

  .table-header h2 {
    font-size: 16px;
  }

  .nuevo-btn {
    padding: 6px 10px;
    font-size: 12px;
  }
}
</style>

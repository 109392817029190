import axios from '@/plugins/axios';

export const fetchBrandList = async (vehicleType) => {
  try {
    const response = await axios.get('/prices/brand-list', {
      params: { vehiculeType: vehicleType },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de marcas:', error);
    throw error;
  }
};

export const fetchModelList = async (vehicleType, brandId) => {
  try {
    const response = await axios.get('/prices/model-list', {
      params: {
        vehiculeType: vehicleType,
        vehiculeBrandId: brandId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de modelos:', error);
    throw error;
  }
};

export const fetchVersionList = async (vehicleType, brandId, modelId) => {
  try {
    const response = await axios.get('/prices/version-list', {
      params: {
        vehiculeType: vehicleType,
        vehiculeBrandId: brandId,
        vehiculeModelId: modelId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de versiones:', error);
    throw error;
  }
};

export const fetchVehiculeData = async (vehicleType, brandId, modelId = null, versionId = null) => {
    try {
      const params = {
        vehiculeType: vehicleType,
        vehiculeBrandId: brandId,
      };
  
      if (modelId) {
        params.vehiculeModelId = modelId;
      }
      if (versionId) {
        params.vehiculeVersionId = versionId;
      }
  
      const response = await axios.get('/prices/get-vehicules', {
        params,
      });
  
      return response.data;
    } catch (error) {
      console.error('Error al obtener los datos del vehículo:', error);
      throw error;
    }
  };
  


export const fetchPdfList = async (vehicleType, brandId, modelId, versionId) => {
  try {
    const response = await axios.get('/prices/download-vehicules', {
      params: {
        vehiculeType: vehicleType,
        vehiculeBrandId: brandId,
        vehiculeModelId: modelId,
        vehiculeVersionId: versionId,
      },
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener el PDF:', error);
    throw error;
  }
};

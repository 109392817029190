<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="step-title">PASO {{ currentStep.step }} de 5</h2>
      </div>
      <VehicleFilterButton
        :buttons="currentStep.buttons"
        @reset-filters="resetFilters"
      />
    </div>

    <div class="row" v-if="currentStep.progress <= 100">
      <div class="col-12">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: currentStep.progress + '%' }"
            aria-valuenow="currentStep.progress"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>

    <VehicleTypeStep
      v-if="currentStep.step === 1"
      @updateStepProgress="updateStepProgress"
      @vehicleSelected="selectedVehicle"
    />
    <VehicleBrandStep
      v-if="currentStep.step === 2"
      :brands="brands"
      @updateStepProgress="updateStepProgress"
      @brandSelected="selectedBrand"
    />
    <VehicleModelStep
      v-if="currentStep.step === 3"
      :models="models"
      @updateStepProgress="updateStepProgress"
      @modelSelected="selectedModel"
    />
    <VehicleVersionStep
      v-if="currentStep.step === 4"
      :versions="versions"
      @updateStepProgress="updateStepProgress"
      @versionSelected="selectedVersion"
    />
    <VehiclePdfStep
      v-if="currentStep.step === 5"
      :vehiculeHtml="vehiculeHtml"
      @fetchPdfList="downloadPdf"
    />
    <div class="important-text">
      <p>
        <strong>Importante:</strong> <br />
        <strong
          >Los precios de las unidades 0 Km. y usados están expresados en miles. </strong
        >Se componen de IVA y Valor Básico. Los valores de automotores usados
        detallados en la presente Guía, se refieren a unidades en condiciones
        satisfactorias de uso. Dichos valores rigen solamente para Capital
        Federal y Gran Buenos Aires, pudiendo sufrir variaciones en mas o menos,
        de acuerdo a las condiciones operativas existentes en las distintas
        zonas del país y están expresados en miles. La encuesta de precios es
        relevada permanentemente por la Comisión de Valuación de Vehículos de
        ACARA, la cual integra representante de distintos concesionarios de
        marca que operan en el mercado. Dicha Comisión, en el transcurso de una
        reunión que celebran a la finalización de cada mes, se acuerdan las
        diferentes valorizaciones para cada marca, modelo y año, sobre la base
        del desenvolvimiento comercial observado en cada línea o segmento
        durante el último periodo mensual. A efectos de cualquier tipo de
        valorización que se haga en base de los datos contenidos en la presente
        publicación, considérese como una guía de precios y que pueden existir
        diferencias con respecto al tipo de vehículo y /u operación de que se
        trate. Los valores de 0km pueden incluir bonificaciones de las
        terminales. Queda terminantemente prohibida la reproducción total o
        parcial de su información, su violación implicara para el infractor la
        aplicación de las penas previstas por el Art..172 del Código Penal,
        según ley 11.723.
      </p>
    </div>

    <div class="row" v-if="currentStep.step === 3 || currentStep.step === 4 || currentStep.step === 5">
      <div class="col-12">
        <p class="download-pdf">
          <a @click.prevent="downloadPdf" target="_blank"
            >Descargar PDF con todos los precios</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  fetchBrandList,
  fetchModelList,
  fetchVersionList,
  fetchVehiculeData,
  fetchPdfList,
} from "@/services/api/price";
import VehicleTypeStep from "@/components/precios/steps/VehicleTypeStep.vue";
import VehicleBrandStep from "@/components/precios/steps/VehicleBrandStep.vue";
import VehicleModelStep from "@/components/precios/steps/VehicleModelStep.vue";
import VehicleVersionStep from "@/components/precios/steps/VehicleVersionStep.vue";
import VehiclePdfStep from "@/components/precios/steps/VehiclePdfStep.vue";
import VehicleFilterButton from "@/components/precios/VehicleFilterButton.vue";

export default {
  name: "StepPrecios",
  components: {
    VehicleTypeStep,
    VehicleBrandStep,
    VehicleModelStep,
    VehicleVersionStep,
    VehiclePdfStep,
    VehicleFilterButton,
  },
  setup() {
    const originalSteps = [
      { step: 1, progress: 25, buttons: [] },
      { step: 2, progress: 50, buttons: [] },
      { step: 3, progress: 75, buttons: [] },
      { step: 4, progress: 100, buttons: [] },
      { step: 5, progress: 101, buttons: [] },
    ];

    const steps = ref([...originalSteps]);

    const vehicleSelected = ref(null);
    const brandSelected = ref(null);
    const modelSelected = ref(null);
    const versionSelected = ref(null);

    const currentStep = ref({ ...steps.value[0] });
    const brands = ref([]);
    const models = ref([]);
    const versions = ref([]);
    const vehiculeHtml = ref("");

    const updateStepProgress = async ({ step }) => {
      const stepData = steps.value.find((s) => s.step === step);
      if (stepData) {
        currentStep.value = {
          ...stepData,
          buttons: [...currentStep.value.buttons],
        };
      }
    };

    const updateButtons = (key, value) => {
      const updatedButtons = [...currentStep.value.buttons];
      updatedButtons.push({ [key]: value });
      currentStep.value.buttons = updatedButtons;
    };

    const selectedVehicle = async ({ vehicle }) => {
      vehicleSelected.value = vehicle;
      updateButtons("vehicle", vehicle.name);
      await loadBrandList();
    };

    const selectedBrand = async ({ brand }) => {
      brandSelected.value = brand;
      updateButtons("brand", brand.name);
      await loadModelList();
    };

    const selectedModel = async ({ model }) => {
      modelSelected.value = model;
      updateButtons("model", model.name);
      await loadVersionList();
    };

    const selectedVersion = async ({ version }) => {

      if (isNaN(Number(version.id))) {
        versionSelected.value = null;
      } else {
        versionSelected.value = version;
        updateButtons("version", version.name);
      }
      await loadVehiculeData();
    };

    const loadBrandList = async () => {
      try {
        brands.value = await fetchBrandList(vehicleSelected.value.id);
      } catch (error) {
        console.error("Error al obtener la lista de marcas:", error);
      }
    };

    const loadModelList = async () => {
      try {
        const modelData = await fetchModelList(
          vehicleSelected.value.id,
          brandSelected.value.id
        );
        insertAllOption(modelData, "TODOS LOS MODELOS", models);
      } catch (error) {
        console.error("Error al obtener la lista de modelos:", error);
      }
    };

    const loadVersionList = async () => {
      try {
        if (isNaN(Number(modelSelected.value.id))) {
          vehiculeHtml.value = await fetchVehiculeData(
            vehicleSelected.value.id,
            brandSelected.value.id
          );
        } else {
          const versionData = await fetchVersionList(
            vehicleSelected.value.id,
            brandSelected.value.id,
            modelSelected.value.id
          );
          insertAllOption(versionData, "TODOS LAS VERSIONES", versions);
        }
      } catch (error) {
        console.error(
          "Error al obtener la lista de versiones o al descargar el PDF:",
          error
        );
      }
    };

    const loadVehiculeData = async () => {
      try {
        vehiculeHtml.value = await fetchVehiculeData(
          vehicleSelected.value.id,
          brandSelected.value.id,
          modelSelected.value.id,
          versionSelected.value?.id
        );
      } catch (error) {
        console.error("Error al obtener los datos del vehículo:", error);
      }
    };

    const insertAllOption = (data, text, target) => {
      if (data && data.data) {
        data.data.unshift({ id: "t", name: text });
        target.value = data;
      }
    };

    const downloadPdf = async () => {
      try {
        const validateId = (value) => {
          const id = Number(value);
          return isNaN(id) || !Number.isFinite(id) ? null : id;
        };

        const vehicleId = validateId(vehicleSelected?.value?.id);
        const brandId = validateId(brandSelected?.value?.id);
        const modelId = validateId(modelSelected?.value?.id);
        const versionId = validateId(versionSelected?.value?.id);

        if (!vehicleId || !brandId) {
          console.error(
            "Error: Faltan parámetros requeridos para la descarga del PDF."
          );
          return;
        }

        const response = await fetchPdfList(
          vehicleId,
          brandId,
          modelId,
          versionId
        );

        if (!response) {
          console.error(
            "Error: Respuesta no válida al intentar descargar el PDF."
          );
          return;
        }

        const blob = new Blob([response], { type: "application/pdf" });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "precios_vehiculos.pdf");
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        link.remove();
      } catch (error) {
        console.error("Error al obtener el PDF:", error);
      }
    };

    const resetFilters = ({ type, index }) => {
      steps.value = JSON.parse(JSON.stringify(originalSteps));
      brands.value = [];

      if (index === 0) {
        currentStep.value.buttons = [];
        updateStepProgress({ step: 1 });
      }

      if (index === 1) {
        currentStep.value.buttons = [];
        updateStepProgress({ step: 2 });
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => !button[type]
        );
        selectedVehicle({ vehicle: vehicleSelected.value });
      }
      if (index === 2) {
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => button.vehicle
        );
        updateStepProgress({ step: 3 });
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => !button[type]
        );
        selectedBrand({ brand: brandSelected.value });
      }
      if (index === 3) {
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => button.vehicle || button.brand
        );
        updateStepProgress({ step: 4 });
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => !button[type]
        );
        selectedModel({ model: modelSelected.value });
      }
      if (index === 4) {
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => button.vehicle || button.brand
        );
        updateStepProgress({ step: 5 });
        currentStep.value.buttons = currentStep.value.buttons.filter(
          (button) => !button[type]
        );
        selectedVersion({ version: versionSelected.value });
      }
    };

    return {
      currentStep,
      brands,
      models,
      versions,
      vehiculeHtml,
      updateStepProgress,
      resetFilters,
      selectedVehicle,
      selectedBrand,
      selectedModel,
      selectedVersion,
      downloadPdf,
    };
  },
};
</script>

<style scoped>
.step-title {
  color: #2cbad2;
  font-weight: 800;
  text-align: center;
}

.progress {
  height: 20px;
  background-color: #e9ecef;
  border-radius: 10px;
}

.progress-bar {
  background-color: #00a0e1;
}

button {
  margin: 5px;
}

.download-pdf a {
  color: #007bff !important;
  text-decoration: none !important;
  cursor: pointer;
}

.download-pdf {
  margin-top: 20px;
}

.important-text {
  margin-top: 50px;
}
</style>

<template>
  <div class="forbidden-container">
    <div class="content">
      <h1>403 - Acceso Denegado</h1>
      <p>No tienes los permisos necesarios para acceder a esta página.</p>
      <router-link to="/" class="back-button">Volver al inicio</router-link>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
.forbidden-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
}

.content {
  max-width: 500px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transform: translateY(-10%);
  animation: fadeIn 0.8s ease-in-out;
}

h1 {
  font-size: 2.5rem;
  color: #dc3545;
  margin-bottom: 15px;
}

p {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 25px;
}

.back-button {
  display: inline-block;
  padding: 12px 25px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
  }

  .back-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

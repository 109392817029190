import DivisionesAutosView from "@/views/divisiones/publico/DivisionesAutos.vue";
import DivisionesMaquinariaAgricolaView from "@/views/divisiones/publico/DivisionesMaquinariaAgricolaView.vue";
import DivisionesMotovehiculoView from "@/views/divisiones/publico/DivisionesMotovehiculoView.vue";
import SociosView from "@/views/servicios/publico/SociosView.vue";
import AsesoriaLegal from "@/views/servicios/publico/AsesoriaLegalView.vue";
import AsesoriaImpositiva from "@/views/servicios/publico/AsesoriaImpositivaView.vue";
import AsesoriaRegistral from "@/views/servicios/publico/AsesoriaRegistralView.vue";
import CapacitacionView from "@/views/servicios/publico/CapacitacionView.vue";

export const publicRoutes = [
  {
    path: "/divisiones-autos",
    name: "divisiones-autos",
    component: DivisionesAutosView,
  },
  {
    path: "/divisiones-motovehiculos",
    name: "divisiones-motovehiculos",
    component: DivisionesMotovehiculoView,
  },
  {
    path: "/divisiones-maquinaria-agricola",
    name: "divisiones-maquinaria-agricola",
    component: DivisionesMaquinariaAgricolaView,
  },
  {
    path: "/socios",
    name: "socios",
    component: SociosView,
  },
  {
    path: "/asesoria-legal",
    name: "Asesoria legal",
    component: AsesoriaLegal,
  },
  {
    path: "/asesoria-impositiva",
    name: "Asesoria impositiva",
    component: AsesoriaImpositiva,
  },
  {
    path: "/asesoria-registral",
    name: "Asesoria registral",
    component: AsesoriaRegistral,
  },
  {
    path: "/capacitacion",
    name: "capacitacion",
    component: CapacitacionView,
  },
];

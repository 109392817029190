<template>
  <section class="aladda">
    <a :href="aladdaUrl" target="_blank">
      <div class="logo-aladda" :style="{ backgroundImage: `url('${logoUrl}')` }"></div>
    </a>
  </section>
</template>


<script>
import { ALADDA_URL } from "@/services/api/aladda.js";

export default {
  name: "AladdaComponent",
  data() {
    return {
      aladdaUrl: ALADDA_URL,
      logoUrl: require('@/assets/images/aladda.png'),
    };
  },
};
</script>


<style scoped>
.aladda {
  text-align: center;
  padding: 25px 0;
  background-color: #f7f7f7;
}

.logo-aladda {
  display: inline-block;
  width: 30vw;
  height: 154px;
  max-width: 372px;
  background-size: cover;
}

@media (max-width: 768px) {
  .logo-aladda {
    width: 50vw;
  }
}

@media (max-width: 480px) {
  .logo-aladda {
    width: 87vw;
  }
}
</style>

<template>
  <div id="motos-informacion">
    <section class="header">
      <div class="title">
        <div class="container">
          <div class="col-12">
            <h3>
              <img :src="iconPath" alt="Guía de Transferencias" height="55" />
              GUÍA DE TRANSFERENCIAS
            </h3>
          </div>
        </div>
      </div>
    </section>

    <section class="informacion">
      <div class="container">
        <div class="row">
          <div class="col-12 text-left">
            <h3>VENDER UNA MOTO USADA</h3>
            <p>
              Hoy en día, pareciera ser sencillo vender una moto. Basta con ver las ofertas online de particulares
              para animarse a hacerlo. Si observamos, notamos que siempre hay un motovehículo que fue publicado, hay un
              comprador que se mostró interesado, ese comprador acordó el precio de la transacción con el vendedor y
              allí se cerró la operación. Hasta ahí fue simple. Luego deja de serlo.
              <br /><br />
              Las complicaciones comienzan con los trámites y principalmente, con el desconocimiento. En el momento
              donde la negociación terminó, empiezan las corridas y con ellas, las inquietudes: ¿hay que hacer la
              verificación policial? ¿Tengo que comprar algún formulario? ¿El 08? ¿El 12? ¿Puedo transferir online? ¿A
              qué registro debo dirigirme?
              <br /><br />
              La División Motovehículos de ACARA aconseja asesorarse siempre con un concesionario para evitar trabas en
              la transacción y estar en manos de especialistas. Pero, para quienes estén interesados en saber, la
              asociación preparó un informe donde detalla al usuario final la guía de pasos a seguir y cuestiones a
              tener en cuenta antes de hacer la entrega del motovehículo.
            </p>

            <h5>1. Verificación Policial (Formulario 12)</h5>
            <p>
              Esta revisión se realiza para constatar que el número de motor, de chasis/cuadro y el tipo de vehículo
              correspondan con lo especificado en la solicitud tipo 12 del mismo. Se efectúan en plantas que están a
              cargo de peritos de las distintas fuerzas policiales de nuestro país, la Federal y Provinciales, y la
              Gendarmería, que están autorizados por la Dirección Nacional del Registro de la Propiedad Automotor.
              <br /><br />
              La verificación física realizada en el puesto verificador tiene una validez de 150 días hábiles
              administrativos y es un trámite personal que no requiere intervención de terceros o intermediarios. Es
              obligatorio este paso antes de realizar la transferencia.
              <br /><br />
              La verificación física puede ser realizada en la planta verificadora más cercana a su domicilio, pero el
              procedimiento varía según el distrito. En Capital Federal, por ejemplo, se acude al puesto policial sin
              reserva de turno y se pagan los aranceles correspondientes al formulario 12 en el lugar. En Provincia de
              Buenos Aires, en cambio, se implementó un sistema online en el que se completan vía web los datos de la
              moto, se realiza el pago online y se acude al puesto policial en la fecha y hora seleccionada para
              verificar los datos.
              <br /><br />
              ACARA recomienda asegurarse en su distrito el procedimiento. Si es online o presencial sin reserva de
              turno. Pero para cualquier caso, al presentarse en la planta o puesto de verificación deberá concurrir
              con:
              <br /><br />
              • En caso de trámite online, presentar comprobante de pago en el turno correspondiente.<br />
              • En caso de ser un distrito con trámite presencial, abonar la solicitud tipo 12 y completar el documento
              en la planta verificadora.<br />
              • Si sos titular del vehículo, con tu DNI y cédula verde.<br />
              • Si sos autorizado a conducir el vehículo, cédula verde, cédula azul y DNI.
            </p>

            <h5>2. Transferencia (Formulario)</h5>
            <p>
              La transferencia es el paso siguiente. Hay dos formas de hacerla: comenzar el trámite online y luego ir a
              firmar al registro o bien hacer todos los pasos directamente en el registro correspondiente. Si el trámite
              se realiza online, actualmente hay un 40% de descuento en el valor de la transferencia.
              <br /><br />
              El formulario 08D es el que se realiza de forma digital. Bajo este formato, tanto comprador como vendedor,
              pueden hacer una precarga de la solicitud tipo 08D. La carga de la información del titular y adquirente,
              puede realizarse conjunta o indistintamente de manera online, luego ambos concurrirán al registro al solo
              efecto de suscribir la firma en dicha solicitud, acompañando el resto de la documentación para completar
              el trámite.
              <br /><br />
              Acá se comienza el trámite online:
              <a href="http://www.dnrpa.gov.ar/portal_dnrpa/" target="_blank">http://www.dnrpa.gov.ar/portal_dnrpa/</a>
              <br /><br />
              El formulario 08 tradicional se completa en el Registro Seccional correspondiente de forma tradicional con
              comprador y vendedor presentes. Se realiza el pago allí y se emiten los comprobantes. A las 48/72 hs está
              disponible la cédula verde del comprador.
              <br /><br />
              <strong>¿Qué documentación debe presentarse?</strong><br /><br />
              • Solicitud Tipo “08”.<br />
              • Verificación en Solicitud Tipo “12” (Verificación Policial).<br />
              • Título del Automotor.<br />
              • Cédula de identificación.<br />
              • N.I., L.C., L.E., etc.: original y una fotocopia.<br />
              • Constancia de CUIT/CUIL (fotocopia).<br />
              • E.T.A. en caso de que corresponda.<br />
              • FORMULARIOS: 13 / 13I.
              <br /><br />
              <strong>¿Dónde se realiza?</strong><br /><br />
              El trámite se realiza en el Registro Seccional donde se encuentra radicado el motovehículo. Es posible
              chequearlo en:
              <a href="http://www.dnrpa.gov.ar/guia_calles/guia_cons_1.php?tipo_registro_seccional=M" target="_blank">
                Registro Seccional
              </a>
            </p>

            <h5>3. Entrega del Motovehículo</h5>
            <p>
              La entrega del motovehículo se realiza posterior al pago y la transferencia inscripta en el registro
              automotor. Es muy común que ofrezcan comprar la moto sin hacer estos trámites pero es necesario aclarar
              que el vendedor no se desliga de responsabilidad alguna si procede a hacerlo de esa forma.
            </p>

            <h5>4. Asesoramiento</h5>
            <p>
              Siempre es recomendable asesorarse con un concesionario o con alguna de las entidades vinculadas al sector
              para evitar inconvenientes en la operación.
            </p>

            <h5>¡IMPORTANTE!</h5>
            <p>
              » ANTES DE ADQUIRIR UN MOTOVEHÍCULO ES NECESARIO APRENDER A MANEJAR Y SACAR LA LICENCIA DE CONDUCIR
              CORRESPONDIENTE.<br /><br />
              » ES IMPORTANTE QUE CUENTES CON LA LICENCIA DE CONDUCIR HABILITANTE PARA LA CILINDRADA QUE ADQUIRISTE.<br /><br />
              » ACARA MOTOS SUGIERE QUE ASEGURES EL MOTOVEHÍCULO EN CUALQUIERA DE LAS COMPAÑÍAS DE SEGURO DISPONIBLES EN
              EL MERCADO.<br /><br />
              » ES UN CONSEJO DE ACARA CONDUCIR RESPONSABLEMENTE UTILIZANDO CASCO Y TODOS LOS ELEMENTOS DE PROTECCIÓN
              ADECUADOS.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <br /><br />
            <button @click="goBack" class="btn-volver">VOLVER</button>
            <br /><br />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { onMounted } from "vue";
export default {
  name: "TransferenciasGuiaComponent",
  setup() {
    const iconPath = require("@/assets/images/icon_guia_transferencias.png");
    const router = useRouter();

    const goBack = () => {
      if (window.history.length > 1) {
        router.go(-1);
      } else {
        router.push("/");
      }
    };

        onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      iconPath,
      goBack,
    };
  },
};
</script>


<style scoped>
#motos-informacion .header {
  background: #001f5b;
  height: 125px;
  position: relative;
  width: 100%;
}
#motos-informacion .header .title {
  position: absolute;
  bottom: 0;
  width: 100%;
}
#motos-informacion .header .title h3 {
  color: #ffffff;
  padding: 0 0 25px 0;
  text-transform: uppercase;
}
#motos-informacion .header img {
  margin-right: 25px;
}
#motos-informacion .informacion {
  background: #ffffff;
  padding: 45px 0 0 0;
  text-align: left;
}
#motos-informacion .informacion h3 {
  color: #00a0e1;
  margin-bottom: 25px;
  text-transform: uppercase;
}
#motos-informacion .informacion h5 {
  color: #00a0e1;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
}
#motos-informacion .btn-volver {
  color: #fff;
  background: #01145b;
  font-size: 1.2rem;
  font-weight: 800;
  padding: 10px 80px;
  border: none;
  cursor: pointer;
}
#motos-informacion .btn-volver:hover {
  background: #00a0e1;
}
</style>

import Swal from "sweetalert2";

export function useSweetAlert() {
  const showDetailsModal = (title, body, details = []) => {
    const detailsHtml = details.length
      ? `<div id="custom-details" style="margin-top: 1rem; font-size: 1rem; color: #666; text-align: left;">
          ${details
            .map(
              detail =>
                `<p><strong>${detail.label}:</strong> <span style="color: ${detail.label === 'Email' ? '#007bff' : '#666'};">${detail.value}</span></p>`
            )
            .join("")}
        </div>`
      : "";

    const bodyHtml = details.length
      ? `<h2 style="font-size: 2.25rem; font-weight: bold; text-align: center; margin-bottom: 2rem;margin-top: 2rem;">${body || "Información no disponible"}</h2>`
      : `${body || "Información no disponible"}`;

    Swal.fire({
      html: `
        <div id="custom-header">
          ${title}
        </div>
        <div id="custom-body">
          ${bodyHtml}
        </div>
        ${detailsHtml}
      `,
      showCloseButton: true,
      showConfirmButton: false,
      width: "590px",
      background: "#fff",
      customClass: {
        popup: "custom-swal-popup",
      },
      didOpen: () => {
        const htmlContainer = document.querySelector(".swal2-html-container");
        if (htmlContainer) {
          htmlContainer.style.padding = "0";
        }

        const header = document.querySelector("#custom-header");
        if (header) {
          header.style.backgroundColor = "#00a0e1";
          header.style.color = "white";
          header.style.fontSize = "1.5rem";
          header.style.fontWeight = "bold";
          header.style.textAlign = "left";
          header.style.padding = "1rem";
        }

        const body = document.querySelector("#custom-body");
        if (body) {
          body.style.padding = "1.5rem";
          body.style.fontSize = "1rem";
          body.style.color = "#333";
          body.style.textAlign = "left";
        }

        const details = document.querySelector("#custom-details");
        if (details) {
          details.style.padding = "1rem";
          details.style.fontSize = "0.9rem";
          details.style.color = "#666";
          details.style.textAlign = "left";
        }
      },
    });
  };

  return {
    showDetailsModal,
  };
}
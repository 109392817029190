import { validateTokenPlain } from "@/services/api/authService";
import router from "@/router";
import store from "@/store";

export function setupResponseInterceptor(instance) {
    instance.interceptors.response.use(
        (response) => response,
        async (error) => {
          if (error.response?.status === 401) {
            const isValid = await validateTokenPlain();

            if (!isValid) {
              console.error("Token no válido. Redirigiendo al login.");
              store.commit("clearUser");
              router.push({ name: "home" });
              return Promise.reject(error);
            }
          }

          return Promise.reject(error);
        }
    );
}

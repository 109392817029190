<template>
  <div class="ingresar">
    <a
      href="#"
      :class="{'nav-link': isMobile}"
      @click.prevent="openLoginModal"
    >
      <div class="link" v-if="!isMobile">INGRESO SOCIOS</div>
      <div class="flecha" v-if="!isMobile"></div>
      <span v-if="isMobile">{{ name }}</span>
    </a>
    <AuthModalComponent ref="authModal" />
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import AuthModalComponent from "./AuthModalComponent.vue";

export default {
  name: "LoginButtonComponent",
  components: {
    AuthModalComponent,
  },
  props: {
    name: {
      type: String,
      default: "INGRESO SOCIOS",
    },
  },
  setup() {
    const isMobile = ref(false);
    const authModal = ref(null);

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    const openLoginModal = () => {
      if (authModal.value) {
        authModal.value.$refs.loginModal.show();
      }
    };

    onMounted(() => {
      checkIsMobile();
      window.addEventListener("resize", checkIsMobile);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", checkIsMobile);
    });

    return {
      isMobile,
      openLoginModal,
      authModal,
    };
  },
};
</script>
<style scoped>
.ingresar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ingresar a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #2CBAD2;
  font-weight: 600;
  padding: 0;
}

.link {
      background: #001f5b;
    color: #fff;
  padding: 5px 20px;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
  margin: 0;
  display: inline-block;
}

.flecha {
  content: '';
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #001f5b;
  display: inline-block;
  margin: 0;
}



@media (max-width: 768px) {
  .ingresar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0; /* Espaciado entre el botón y los bordes */
  }

  .nav-link {
    background-color: #001f5b !important; /* Fondo azul oscuro */
    color: #ffffff !important; /* Texto blanco */
    border-radius: 10px !important; /* Bordes más redondeados */
    font-weight: 600 !important; /* Negrita */
    padding: 6px 9px !important; /* Padding uniforme para mejor equilibrio */
    font-size: 8px !important; /* Tamaño de texto moderado */
    text-align: center;
    display: inline-block; /* Centra el texto dentro del botón */
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2) !important; /* Sombra ligera */
    transition: all 0.3s ease; /* Transición suave en hover */
  }

  .nav-link:hover,
  .nav-link:focus {
    background: #02225f !important; /* Fondo más oscuro en hover */
    color: #ffffff !important;
    transform: translateY(-2px); /* Efecto de desplazamiento hacia arriba */
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3) !important; /* Sombra más pronunciada */
  }

  .nav-link:active {
    transform: scale(0.98) !important; /* Efecto de clic */
  }
}



</style>

import { ref } from "vue";
import { fetchExpirationCalendars } from "@/services/api/calendar";

export function useCalendar() {
  const initialEvents = ref([]);
  const localEvents = ref([]);
  const enabledDates = ref([]);

  const loadCalendarData = async (date) => {
    try {
      const expirationCalendars = await fetchExpirationCalendars(date);
      enabledDates.value = expirationCalendars.map((calendar) => calendar.date);
      initialEvents.value = expirationCalendars;
      localEvents.value = [...initialEvents.value];
    } catch (error) {
      console.error("Error loading calendar data:", error);
    }
  };

  const handleFetchDateEvents = async (date) => {
    try {
      const eventsForDate = await fetchExpirationCalendars(date);
      localEvents.value = eventsForDate;
    } catch (error) {
      console.error("Error fetching events for the selected date:", error);
    }
  };

  const handleMonthChange = async (date) => {
    try {
      await loadCalendarData(date);
    } catch (error) {
      console.error("Error updating calendar data for new month:", error);
    }
  };

  return {
    initialEvents,
    localEvents,
    enabledDates,
    loadCalendarData,
    handleFetchDateEvents,
    handleMonthChange,
  };
}
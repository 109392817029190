<template>
  <section class="header">
    <div class="title">
      <div class="container">
        <div class="col-12">
          <h2>Socios</h2>
        </div>
      </div>
    </div>
  </section>

  <section class="buscador-circulares">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <h2>Reportes</h2>
            </div>
          </div>
        </div>
      </div>

      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col">Año</th>
            <th scope="col">Mes</th>
            <th scope="col">Título</th>
            <th scope="col">Ver</th>
          </tr>
        </thead>
        <tbody class="body-table">
          <tr v-for="(reporte, index) in reportes" :key="index">
            <td>{{ reporte.ano }}</td>
            <td>{{ reporte.mes }}</td>
            <td>{{ reporte.titulo }}</td>
            <td>
              <a :href="getFileUrl(reporte.file)" target="_blank">
                <i class="fas fa-eye" aria-hidden="true"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import config from "@/config";

const reportes = ref([
  {
    ano: 2023,
    mes: "Abril",
    titulo: "Reporte Abril 2023 Autos",
    file: "202304. SIOMAA. Informe de Mercado 4W. LITE.pdf",
  },
  {
    ano: 2023,
    mes: "Abril",
    titulo: "Reporte Abril 2023 Motos",
    file: "202304. SIOMAA. Informe de Mercado 2W. LITE.pdf",
  },
  {
    ano: 2023,
    mes: "Abril",
    titulo: "Reporte Abril 2023 Maquinaria Agrícola",
    file: "MAQUINARIA_Abril_2023.pdf",
  },
]);

const getFileUrl = (fileName) => `${config.backendUrl}/storage/documents/${fileName}`;
</script>

<style scoped>
.header {
  background: url("@/assets/images/bg_socios.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 325px;
  width: 100%;
}

.header .title {
  width: 100%;
}

.header .title h2 {
  padding-top: 65px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 65px;
  font-weight: 800;
}

.buscador-circulares {
  padding: 60px 0;
}

.buscador-circulares h2 {
  color: #2a5aa4;
  font-weight: 800;
  text-transform: uppercase;
}
</style>

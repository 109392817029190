<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h3 class="step-subtitle">SELECCIONE EL MODELO DE TU VEHÍCULO</h3>
      </div>
    </div>

    <div v-if="isLoading" class="loading-indicator">
      Cargando modelos...
    </div>

    <div v-else class="row">
      <div class="col-12">
        <div
          v-for="model in models.data"
          :key="model.id"
          class="model-item"
        >
          <a @click.prevent="selectModel(model)" class="link-selector">
            {{ model.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";

export default {
  name: "VehicleModelStep", 
  props: {
    models: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const currentStep = ref(4);
    const isLoading = ref(true);

    watchEffect(() => {
      if (props.models && props.models.data && props.models.data.length > 0) {
        isLoading.value = false;
      }
    });

    const selectModel = (model) => {
      const nextStep = isNaN(Number(model.id)) ? 5 : currentStep.value;
      emit('updateStepProgress', { step: nextStep  });
      emit('modelSelected', { model: model });
    };

    return {
      selectModel,
      isLoading,
    };
  },
};
</script>

<style scoped>
.step-subtitle {
  color: #2cbad2;
  margin-bottom: 40px;
  margin-top: 40px;
}

.model-item {
  text-align: left; 
  border-bottom: solid 1px #D9D9D9;
  padding: 5px 10px;
}

.link-selector {
  color: #2CBAD2;
  font-weight: 400;
  font-size: 1.3rem;
  text-decoration: none;
  cursor: pointer;
}

.link-selector:hover {
  color: #00a0e1;
}

.loading-indicator {
  text-align: center;
  font-size: 18px;
  color: #2cbad2;
}
</style>

<template>
  <div id="motos-informacion">
    <section class="header">
      <div class="title">
        <div class="container">
          <div class="col-12">
            <h3>
              <img :src="iconPath" alt="Requisitos Licencia de Conducir" height="55" />
              Requisitos para sacar la licencia de conducir
            </h3>
          </div>
        </div>
      </div>
    </section>

    <section class="informacion">
      <div class="container">
        <div class="row">
          <div class="col-12 text-left">
            <p>
              Te contamos a continuación qué cosas necesitás para poder sacar tu licencia:
              El trámite de otorgamiento es personal y sólo lo puede realizar la persona que solicita la misma.
            </p>

            <h3>Para C.A.B.A.</h3>
            <p>
              La primera vez que tramitás tu licencia de conducir, se te otorgará bajo la condición de Principiante.
              Esta condición se mantendrá por 6 (seis) meses, durante los cuales no estarás habilitado para circular en vías donde
              la velocidad máxima supere los 60 km/h, autopistas, ni en el ámbito del microcentro de la Ciudad Autónoma de Buenos Aires.
              <br /><br />
              La condición de Principiante se aplica a aquellas personas que tramitan por primera vez una licencia de conducir para
              categoría A (motos), A4 (cuatriciclos) o B1 (automóviles).
              <br /><br />
              • Para sacar la licencia, necesitás realizar previamente el Curso de Educación Vial para Otorgamiento. Para hacerlo,
              tenés que sacar un turno en el siguiente link:
              <a href="http://www.buenosaires.gob.ar/pedir-nuevo-turno?idPrestacion=1543" target="_blank">
                Curso de Educación Vial
              </a>
              <br /><br />
              • Consultar y resolver tus infracciones de C.A.B.A. Podés hacerlo en este link:
              <a href="http://www.buenosaires.gob.ar/consulta-de-infracciones" target="_blank">
                Consulta de Infracciones
              </a>
              <br /><br />
              En caso de tener multas pendientes, deberás abonarlas voluntariamente o resolverlas con un controlador, previamente al inicio del trámite.
              Importante: para este trámite no es necesario que emitas el Certificado de Libre Deuda.
              <br /><br />
              • Tramitar el Certificado Nacional de Antecedentes de Tránsito (CENAT):
              <a href="http://www.buenosaires.gob.ar/licenciasdeconducir/requisitos/antecedentestransito" target="_blank">
                CENAT
              </a>
              <br /><br />
              Imprimí y aboná la boleta de pago para emitir tu certificado. Recordá pagarla hasta 96 hs antes de asistir a la Sede de Licencias,
              para asegurar su acreditación. La validez de la misma se extenderá por 90 días.
              Recordá llevar el comprobante de pago del CENAT.
              <br /><br />
              • Sacar un Turno para el Otorgamiento de la Licencia y confirmarlo al abonar la boleta (BUI):
              <a href="http://www.buenosaires.gob.ar/pedir-nuevo-turno?idPrestacion=1342" target="_blank">
                Turno para Licencia
              </a>
              <br /><br />
              Es importante que tengas en cuenta que para realizar el trámite deberás contar con un correo electrónico válido donde te llegará
              toda la documentación e información que necesitás, además de la fecha límite de pago.
              <br /><br />
              • Tramitar el Certificado de Antecedentes Penales (solo para licencia profesional – categoría D):
              <a href="http://www.buenosaires.gob.ar/licenciasdeconducir/requisitos/antecedentespenales" target="_blank">
                Certificado de Antecedentes Penales
              </a>
              <br /><br />
              • Si sos menor de 18 años, tenés otros requisitos adicionales:
              <a href="http://www.buenosaires.gob.ar/licenciasdeconducir/casosespeciales/menores" target="_blank">
                Requisitos para Menores
              </a>
              <br /><br />
              • Debés presentar DNI con domicilio en C.A.B.A.: original y fotocopia.
              <br /><br />
              En caso de padecer alguna enfermedad o estar bajo tratamiento médico, traé certificados, informes y/o estudios actualizados de las patologías.
              <br /><br />
              En caso de licencias extranjeras y vencidas por más de un año:
              <br /><br />
              • Presentá tu licencia anterior (en caso de robo o pérdida, llevá la denuncia policial).<br />
              • Presentá el Certificado de Legalidad: sólo debes hacer este trámite si tu Licencia anterior es de otra jurisdicción.
                Al presentarlo, no se te asignará la categoría de Principiante.
            </p>

            <h5>Sedes donde se puede realizar el trámite</h5>
            <p>
              • Sede Central: Av. Roca 5252 - de 7.30 a 14.30 hs.<br />
              • Sede Comunal 13: Av. Cabildo 3067 - lunes a viernes de 8.30 a 14 hs.<br />
              • ACA Libertador: Av. Libertador 1850 de 7.30 a 13.00 hs (sólo socios).<br />
            </p>

            <h5>Para Provincia</h5>
            <p>
              El trámite debe realizarse en la dependencia municipal correspondiente al domicilio que figura en tu DNI.
              La podrás retirar en la dependencia y en el plazo que te indiquen en tu municipio.
              <br /><br />
              <strong>¿Qué necesitás para sacar la licencia por primera vez?</strong>
              <br /><br />
              a) Presentar original y copia de DNI.<br />
              b) Presentar grupo y factor sanguíneo del titular.<br />
              c) Acompañar autorización del representante legal, si fuere menor de 18 años.<br />
              d) Saber leer y escribir.<br />
              e) Encontrarse habilitado para la/s clases que se solicita.<br />
              f) Tener libre deuda de infracciones de tránsito.<br />
              g) Asistir al curso teórico-práctico de educación para la seguridad vial.<br />
              h) Aprobar los exámenes médico psicofísico y teórico-prácticos requeridos.<br />
              i) En el caso de conductores profesionales se requerirán conocimientos específicos y Certificado de Antecedentes Penales.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <br /><br />
            <button @click="goBack" class="btn-volver">VOLVER</button>
            <br /><br />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { onMounted } from "vue";
export default {
  name: "LicenciaConducirRequisitos",
  setup() {
    const iconPath = require("@/assets/images/icon_licencia_conducir.png"); 
    const router = useRouter();

    const goBack = () => {
      if (window.history.length > 1) {
        router.go(-1);
      } else {
        router.push("/");
      }
    };

            onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      iconPath,
      goBack,
    };
  },
};
</script>

<style scoped>
#motos-informacion .header {
  background: #001f5b;
  height: 125px;
  position: relative;
  width: 100%;
}
#motos-informacion .header .title {
  position: absolute;
  bottom: 0;
  width: 100%;
}
#motos-informacion .header .title h3 {
  color: #ffffff;
  padding: 0 0 25px 0;
  text-transform: uppercase;
}
#motos-informacion .header img {
  margin-right: 25px;
}
#motos-informacion .informacion {
  background: #ffffff;
  padding: 45px 0 0 0;
  text-align: left;
}
#motos-informacion .informacion h3 {
  color: #00a0e1;
  margin-bottom: 25px;
  text-transform: uppercase;
}
#motos-informacion .informacion h5 {
  color: #00a0e1;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
}
#motos-informacion .btn-volver {
  color: #fff;
  background: #01145b;
  font-size: 1.2rem;
  font-weight: 800;
  padding: 10px 80px;
  border: none;
  cursor: pointer;
}
#motos-informacion .btn-volver:hover {
  background: #00a0e1;
}
</style>

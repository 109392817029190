import { reactive } from "vue";

export default function useValidations() {
  const errors = reactive({});

  const validateFields = (formData) => {
    Object.keys(errors).forEach((key) => delete errors[key]);

    if (!formData.name || formData.name.trim() === "") {
      errors.name = "El campo Nombre es obligatorio.";
    }
    if (!formData.lastname || formData.lastname.trim() === "") {
      errors.lastname = "El campo Apellido es obligatorio.";
    }
    if (!formData.email || formData.email.trim() === "") {
      errors.email = "El campo Email es obligatorio.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "El formato del Email no es válido.";
    }
    if (!formData.business_phone || formData.business_phone.trim() === "") {
      errors.business_phone = "El campo Teléfono Celular es obligatorio.";
    }
    if (!formData.positions || formData.positions.length === 0) {
      errors.positions = "Debe seleccionar al menos un área.";
    }

    return Object.keys(errors).length === 0;
  };

  const clearError = (field) => {
    if (errors[field]) {
      delete errors[field];
    }
  };

  return {
    errors,
    validateFields,
    clearError,
  };
}

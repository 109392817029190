import axios from "@/plugins/axios";

export const fetchAliquots = async (provinceId, aliquotTypeId) => {
  try {
    const response = await axios.get("aliquots", {
      params: {
        arUid: provinceId,
        aliquotTypeId: aliquotTypeId,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error al obtener las alícuotas:", error);
    throw error;
  }
};

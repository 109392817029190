import { ref } from "vue";
import Swal from "sweetalert2";
import { approveRegistration, rejectRegistration } from "@/services/api/registration.js";

export default function useRegistrationActions(fetchRegistrations) {
  const isProcessing = ref(false);

  const confirmApprove = async (registrationId, resolve) => {
    if (isProcessing.value) return;
    isProcessing.value = true;

    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción aprobará el registro.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, aprobar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await approveRegistration(registrationId);
          Swal.fire("¡Aprobado!", "El registro ha sido aprobado.", "success");
          await fetchRegistrations();
          if (resolve) resolve();
        } catch (error) {
          Swal.fire("Error", "No se pudo aprobar el registro.", "error");
          console.error("Error approving registration:", error);
        } finally {
          isProcessing.value = false;
        }
      } else {
        isProcessing.value = false;
      }
    });
  };

  const confirmReject = async (registrationId, resolve) => {
    if (isProcessing.value) return;
    isProcessing.value = true;

    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción rechazará el registro.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Sí, rechazar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await rejectRegistration(registrationId);
          Swal.fire("¡Rechazado!", "El registro ha sido rechazado.", "success");
          await fetchRegistrations();
          if (resolve) resolve();
        } catch (error) {
          Swal.fire("Error", "No se pudo rechazar el registro.", "error");
          console.error("Error rejecting registration:", error);
        } finally {
          isProcessing.value = false;
        }
      } else {
        isProcessing.value = false;
      }
    });
  };

  return {
    isProcessing,
    confirmApprove,
    confirmReject,
  };
}

<template>
  <section class="resultados-circulares mt-5">
    <div class="container">
      <h3 class="text-center resultados-title">RESULTADOS DE SU BÚSQUEDA</h3>
      <div v-if="loading" class="text-center">Cargando...</div>
      <div v-else>
        <div class="table-responsive">
          <table class="table text-center">
            <thead>
              <tr class="table-header">
                <th scope="col">Nº</th>
                <th scope="col">Tema</th>
                <th scope="col">Título</th>
                <th scope="col">Fecha</th>
                <th scope="col">Ver</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(circular, index) in circulares.data"
                :key="circular.id"
                :class="index % 2 === 1 ? 'table-row-striped' : ''"
              >
                <td>{{ circular.number }}</td>
                <td>{{ circular.circular_topic }}</td>
                <td>{{ circular.title }}</td>
                <td>{{ formatDate(circular.date) }}</td>
                <td>
                  <a
                    v-if="circular.file"
                    @click.prevent="handleFileDownload(circular)"
                    class="download-icon"
                  >
                    <i class="fas fa-download"></i>
                  </a>

                  <span v-else>No disponible</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="circulares.meta.last_page > 1"
          class="pagination-container d-flex flex-wrap justify-content-center gap-2 mt-3"
        >
          <button
            @click="$emit('fetchCirculars', 1)"
            class="btn btn-sm btn-outline-secondary"
            :disabled="circulares.meta.current_page === 1"
          >
            Primera
          </button>
          <button
            @click="$emit('fetchCirculars', circulares.meta.current_page - 1)"
            class="btn btn-sm btn-outline-secondary"
            :disabled="circulares.meta.current_page === 1"
          >
            Anterior
          </button>
          <button
            @click="$emit('fetchCirculars', circulares.meta.current_page + 1)"
            class="btn btn-sm btn-outline-secondary"
            :disabled="
              circulares.meta.current_page >= circulares.meta.last_page
            "
          >
            Siguiente
          </button>
          <button
            @click="$emit('fetchCirculars', circulares.meta.last_page)"
            class="btn btn-sm btn-outline-secondary"
            :disabled="
              circulares.meta.current_page === circulares.meta.last_page
            "
          >
            Última
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import config from "@/config";
import Swal from "sweetalert2";
import axios from "@/plugins/axios";

export default {
  name: "CircularResults",
  props: {
    circulares: {
      type: Object,
      required: true,
      default: () => ({ data: [], meta: { current_page: 1, last_page: 1 } }),
    },
  },
  setup() {
    const loading = ref(false);

    const formatDate = (date) => {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    };

    const generateFileUrl = (circularId) => {
      return `${config.backendBaseUrl}/circulars/${circularId}/file`;
    };

    const handleFileDownload = async (circular) => {
      const url = generateFileUrl(circular.id);
      try {
        const response = await axios.get(url, {
          responseType: "blob",
        });
        const blob = response.data;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = circular.file.split("/").pop();
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        let errorMessage =
          "Ocurrió un error inesperado. Por favor, inténtelo nuevamente.";

        if (error.response) {
          switch (error.response.status) {
            case 404:
              errorMessage =
                "El archivo solicitado no fue encontrado. Verifique la información e intente nuevamente.";
              break;
            case 403:
              errorMessage = "No tiene permisos para descargar este archivo.";
              break;
            case 500:
              errorMessage =
                "Hubo un problema en el servidor. Por favor, intente más tarde.";
              break;
            default:
              errorMessage =
                error.response.data?.message ||
                "Ocurrió un error al procesar la solicitud.";
          }
        } else if (error.message.includes("Network Error")) {
          errorMessage =
            "No se pudo conectar al servidor. Verifique su conexión a internet.";
        }

        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
        });
      }
    };

    return {
      loading,
      formatDate,
      generateFileUrl,
      handleFileDownload,
    };
  },
};
</script>

<style scoped>
.resultados-circulares {
  padding: 20px 0;
}

.resultados-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #01145b;
  margin-bottom: 30px;
}

.table {
  margin-top: 20px;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.table-header {
  background-color: #f4f4f4;
  font-weight: bold;
  color: #01145b;
}

.table-row-striped {
  background-color: #f9f9f9;
}

.table td,
.table th {
  vertical-align: middle;
}

.download-icon {
  color: #01145b;
  font-size: 1.2rem;
}

.download-icon:hover {
  color: #004bb7;
}

.pagination-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.pagination-container button {
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-container button:disabled {
  background-color: #ccc;
  color: #fff;
  cursor: not-allowed;
}

.pagination-container button.active {
  background-color: #004bb7;
  color: white;
  font-weight: bold;
  pointer-events: none;
}

@media (max-width: 768px) {
  .pagination-container {
    flex-direction: column;
    gap: 5px;
  }
  .pagination-container button {
    width: 100%;
    font-size: 16px;
    padding: 10px 0;
  }
  .resultados-title {
    font-size: 1.2rem;
  }
}
</style>

import axios from '@/plugins/axios';

/**
 * Obtiene todas las circulares con filtros opcionales.
 * @param {Object} params - Parámetros para filtrar las circulares.
 * @returns {Promise} Promesa con los resultados de las circulares.
 */
export const fetchCirculars = async (params = {}) => {
  const response = await axios.get('/circulars/search', { params });
  return response.data;
};

/**
 * Obtiene los temas de las circulares.
 * @returns {Promise} Promesa con los temas.
 */
export const fetchCircularTopics = async () => {
  const response = await axios.get('/circulars/topics');
  return response.data;
};


/**
 * Obtiene los detalles de una circular específica.
 * @param {number|string} circularId - ID de la circular.
 * @returns {Promise} Promesa con los detalles de la circular.
 */
export const fetchCircularDetails = async (circularId) => {
  const response = await axios.get(`/circulars/${circularId}`);
  return response.data;
};

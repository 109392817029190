import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import SobreNosotros from "@/views/SobreNosotros.vue";
import SobreNosotrosPrivado from "@/views/SobreNosotrosPrivado.vue";
import { publicRoutes } from "./publicRoutes";
import { privateRoutes } from "./privateRoutes";
import ContactoView from "@/views/contact/Contacto.vue";
import ForbiddenView from "@/views/ForbiddenView.vue";
import PreciosView from "@/views/PreciosView.vue"; 
import ComisionDirectivaView from "@/views/comision-directiva/ComisionDirectivaView.vue"; 
import CircularesView from "@/views/servicios/components/socios/CircularesComponent.vue";
import NovedadArticulo from "@/views/NovedadArticulo.vue";
import MultiStepForm from "@/views/members/MultiStepForm.vue";
import AdministradorUsuariosView from "@/views/administrador/AdministradorUsuariosView.vue"; 
import GuiaPatentamientoMotos from "@/views/divisiones/components/GuiaPatentamientoMotos.vue";
import GuiaTransferenciasMotos from "@/views/divisiones/components/GuiaTransferenciasMotos.vue";
import LicenciaConducirRequisitos from "@/views/divisiones/components/LicenciaConducirRequisitos.vue";
import DocumentacionMotoComponent from "@/views/divisiones/components/DocumentacionMotoComponent.vue";
import ReportesComponent from "@/views/servicios/components/socios/ReportesComponent.vue";
import RegistrationView from "@/views/administrador/solicitudes/RegistrationView.vue"; 
import store from "@/store";
import { validateToken } from "@/services/api/authService";
import { fetchCurrentUser } from "@/services/api/user"; 

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/sobre-nosotros",
    name: "sobre-nosotros",
    component: SobreNosotros,
  },
  {
    path: "/sobre-nosotros-privado",
    name: "sobre-nosotros-privado",
    component: SobreNosotrosPrivado,
  },
  ...publicRoutes, // Rutas públicas
  ...privateRoutes, // Rutas privadas
  {
    path: "/contacto",
    name: "contacto",
    component: ContactoView,
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: ForbiddenView,
  },
  {
    path: "/guia-oficial-de-precios", 
    name: "precios",
    component: PreciosView,
  },
  {
    path: "/comision-directiva", 
    name: "comisionDirectiva",
    component: ComisionDirectivaView,
  },
  {
    path: "/socios-circulares", 
    name: "circulares",
    component: CircularesView,
  },
  {
    path: "/novedades/:slugTitle/:id",
    name: "novedades",
    component: NovedadArticulo,
    props: true, 
  },
  {
    path: "/socios-alta",
    name: "MultiStepForm",
    component: MultiStepForm,
  },
  {
    path: "/administrador/usuarios",
    name: "administrador-usuarios",
    component: AdministradorUsuariosView,
    meta: { requiresAuth: true }, 
  },
  {
    path: "/motos-guia-patentamientos",
    name: "GuiaPatentamientoMotos",
    component: GuiaPatentamientoMotos,
  },
  {
    path: "/motos-guia-transferencias",
    name: "GuiaTransferenciasMotos",
    component: GuiaTransferenciasMotos,
  },
  {
    path: "/motos-licencia-conducir",
    name: "LicenciaConducirRequisitos",
    component: LicenciaConducirRequisitos,
  },

  {
    path: "/motos-documentacion-necesaria",
    name: "DocumentacionMotoComponent",
    component: DocumentacionMotoComponent,
  },
  {
    path: "/socios-reportes",
    name: "SociosReportes",
    component: ReportesComponent,
  },

  {
    path: "/administrador/solicitudes-acceso",
    name: "solicitudes-acceso",
    component: RegistrationView,
    meta: { requiresAuth: true }, 
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach(async (to, from, next) => {
  const token = store.getters.getToken; 
  const isAuthenticated = !!token; 

  if (token) {
    try {
      const isValid = await validateToken();

      if (isValid) {
        try {
          const userData = await fetchCurrentUser(); 

          const currentToken = store.getters.getToken; 
          const user = userData.data;
          user.token = currentToken; 

          store.commit("setUser", user); 
        } catch (error) {
          console.error("Error al obtener los datos del usuario:", error);
          store.commit("clearUser");
          next({ name: "home" });
          return;
        }
      } else {
        console.error("Token inválido. Redirigiendo al login.");
        store.commit("clearUser");
        next({ name: "home" });
        return;
      }
    } catch (error) {
      console.error("Error al validar el token:", error);
      store.commit("clearUser");
      next({ name: "home" });
      return;
    }
  }

  if (to.meta.requiresAuth) {
    if (isAuthenticated) {
      next(); 
    } else {
      next({ name: "home" });
    }
  } else {
    next();
  }
});

export default router;
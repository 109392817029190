import axios from '@/plugins/axios';


export const fetchAreas = async (params = {}) => {
  try {
    const response = await axios.get('/areas', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching areas:', error);
    throw error;
  }
};
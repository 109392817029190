<template>
  <div class="aliquot-container">
    <div class="aliquot-buttons">
      <button
        class="aliquot-button"
        :class="{ active: selectedCategory === 'ingresosBrutos' }"
        @click="updateAliquotType(1)"
      >
        INGRESOS BRUTOS
      </button>
      <button
        class="aliquot-button"
        :class="{ active: selectedCategory === 'sellos' }"
        @click="updateAliquotType(2)"
      >
        SELLOS
      </button>
    </div>
     <h2 class="table-title">{{ tableTitle }}</h2>
    <div v-if="loading" class="loading-indicator">Cargando...</div>
    <div v-else class="aliquot-table">
     
      <table>
        <thead>
          <tr>
            <th>Código</th>
            <th>Actividad</th>
            <th>Alícuota</th>
            <th v-if="aliquotTypeId === 1">base</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in aliquots"
            :key="index"
            :class="{ 'row-alt': index % 2 !== 0 }"
          >
            <td>{{ item.code && item.code.trim() ? item.code : "N/A" }}</td>
            <td>
              {{
                item.activity && item.activity.trim() ? item.activity : "N/A"
              }}
            </td>
            <td>
              {{ item.aliquot && item.aliquot.trim() ? item.aliquot : "N/A" }}
            </td>
            <td v-if="aliquotTypeId === 1">
              <button
                class="view-button"
                v-if="item.base !== null"
                @click="viewDetails(item)"
              >
                🔍
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { fetchAliquots as fetchAliquotsApi } from "@/services/api/aliquot";
import { useSweetAlert } from "@/composables/useSweetAlert";

export default {
  name: "AliquotTableComponent",
  props: {
    selectedProvinceId: {
      type: Number,
      default: null,
    },
    selectedProvince: {
      type: Object,
      default: () => ({
        id: "AR-C",
        name: "Ciudad Autónoma de Buenos Aires",
        CNTRY_NAME: "Argentina",
        TYPE: "District",
      }),
    },
  },
  setup(props) {
    const { showDetailsModal } = useSweetAlert();
    const aliquots = ref([]);
    const loading = ref(false);
    const selectedCategory = ref("ingresosBrutos");
    const aliquotTypeId = ref(1);

    const tableTitle = ref(props.selectedProvince?.name || "Provincia");

    const viewDetails = (item) => {
      showDetailsModal("BASE", item.base);
    };

    const fetchAliquots = async () => {
      if (!props.selectedProvince?.id) return;

      loading.value = true;

      try {
        const data = await fetchAliquotsApi(props.selectedProvince.id, aliquotTypeId.value);
        aliquots.value = data;
        tableTitle.value = props.selectedProvince?.name || "Provincia";
      } catch (error) {
        console.error("Error al obtener las alícuotas:", error);
      } finally {
        loading.value = false;
      }
    };

    const updateAliquotType = (typeId) => {
      aliquotTypeId.value = typeId;
      selectedCategory.value = typeId === 1 ? "ingresosBrutos" : "sellos";
      fetchAliquots();
    };

    watch(() => props.selectedProvince, fetchAliquots, { immediate: true });

    return {
      aliquots,
      loading,
      selectedCategory,
      tableTitle,
      updateAliquotType,
      aliquotTypeId,
      viewDetails,
    };
  },
};

</script>

<style scoped>
.no-padding-container {
  padding: 0 !important;
}

.swal2-html-container {
  padding: 0 !important;
}

.aliquot-container {
  padding: 2rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 600px;
}

.aliquot-buttons {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 0.5rem;
}

.aliquot-button {
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;
}

.aliquot-button.active {
  background-color: #007bff;
  color: white;
}

.aliquot-button:hover {
  background-color: #0056b3;
  color: white;
}

.loading-indicator {
  font-size: 1.2rem;
  color: #007bff;
  text-align: center;
}

.aliquot-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
  padding-right: 35px;
}

.table-container {
  flex: 1;
  overflow-y: auto;
  max-height: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  text-align: left;
}

th,
td {
  padding: 0.8rem;
  border: 1px solid #ddd;
}

th {
  background-color: #f8f9fa;
  text-transform: uppercase;
  font-weight: bold;
}

.row-alt {
  background-color: #f8f9fa;
}

.view-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #007bff;
}

.view-button:hover {
  color: #0056b3;
}

.table-title {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #00a0e1;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .aliquot-buttons {
    justify-content: center;
  }

  .aliquot-button {
    font-size: 0.9rem;
    padding: 0.4rem 1rem;
  }

  .table-title {
    font-size: 1.2rem;
    text-align: center; 
  }

  th,
  td {
    padding: 0.6rem; 
    font-size: 0.9rem;
  }

  .loading-indicator {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .aliquot-buttons {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .aliquot-button {
    width: 100%;
    text-align: center;
  }

  th,
  td {
    font-size: 0.8rem;
    padding: 0.4rem; 
  }

  .table-title {
    font-size: 1rem;
  }
}
</style>

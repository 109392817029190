<template>
  <div id="contacto">
    <section class="header">
      <div class="title">
        <div class="container">
          <h2>{{ title }}</h2>
        </div>
      </div>
    </section>

    <section class="form-contacto">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4 info-text">
            <p>
              Dejanos tu consulta o comentario completando el formulario.<br />
              Enseguida te vamos a estar respondiendo.
            </p>
          </div>

          <div class="col-12 col-md-8">
            <form @submit.prevent="submitForm" class="contact-form">
              <div class="form-group">
                <input
                  type="text"
                  v-model="formData.businessName"
                  class="form-control"
                  placeholder="Razón Social"
                  @input="clearError('businessName')"
                />
                <span v-if="errors.businessName" class="error-text">
                  {{ errors.businessName }}
                </span>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  v-model="formData.name"
                  class="form-control"
                  placeholder="Nombre"
                  @input="clearError('name')"
                />
                <span v-if="errors.name" class="error-text">
                  {{ errors.name }}
                </span>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  v-model="formData.lastname"
                  class="form-control"
                  placeholder="Apellido"
                  @input="clearError('lastname')"
                />
                <span v-if="errors.lastname" class="error-text">
                  {{ errors.lastname }}
                </span>
              </div>
              <div class="form-group">
                <input
                  type="email"
                  v-model="formData.email"
                  class="form-control"
                  placeholder="Correo Electrónico"
                  @input="clearError('email')"
                />
                <span v-if="errors.email" class="error-text">
                  {{ errors.email }}
                </span>
              </div>
              <div class="form-group">
                <input
                  type="tel"
                  v-model="formData.phone"
                  class="form-control"
                  placeholder="Teléfono"
                  @input="sanitizePhone"
                />
                <span v-if="errors.phone" class="error-text">
                  {{ errors.phone }}
                </span>
              </div>
              <div class="form-group">
                <select
                  v-model="formData.division"
                  class="form-control"
                  @input="clearError('division')"
                >
                  <option value="" disabled>Seleccionar División</option>
                  <option value="Division 1">División 1</option>
                  <option value="Division 2">División 2</option>
                  <option value="Division 3">División 3</option>
                </select>
                <span v-if="errors.division" class="error-text">
                  {{ errors.division }}
                </span>
              </div>
              <div class="form-group">
                <textarea
                  v-model="formData.comment"
                  class="form-control"
                  rows="6"
                  placeholder="Comentario / Consulta"
                  @input="clearError('comment')"
                ></textarea>
                <span v-if="errors.comment" class="error-text">
                  {{ errors.comment }}
                </span>
              </div>
              <div class="form-actions">
                <button
                  type="submit"
                  class="btn btn-enviar"
                  :disabled="isSubmitting"
                >
                  ENVIAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, computed  } from "vue";
import { useRoute } from "vue-router";
import { submitContactForm } from "@/services/api/contact.js";
import useContactValidations from "@/composables/useContactValidations.js";
import Swal from "sweetalert2";

export default {
  name: "ContactForm",
  setup() {
    const route = useRoute();
    const formData = ref({
      businessName: "",
      name: "",
      lastname: "",
      email: "",
      phone: "",
      division: "",
      comment: "",
    });

    const isSubmitting = ref(false);

    const { errors, validateFields, clearError } = useContactValidations();

    const title = computed(() => route.query.title || "CONTACTATE");

    const submitForm = async () => {
      if (validateFields(formData.value)) {
        isSubmitting.value = true;

        try {
          await submitContactForm({
            business_name: formData.value.businessName,
            name: formData.value.name,
            lastname: formData.value.lastname,
            email: formData.value.email,
            phone: formData.value.phone,
            division: formData.value.division,
            message: formData.value.comment,
          });

          Swal.fire({
            icon: "success",
            title: "Formulario Enviado",
            text: "Gracias por tu consulta. Nos pondremos en contacto contigo.",
            confirmButtonText: "Cerrar",
          });

          resetForm();
        } catch (error) {
          console.error("Error al enviar el formulario:", error);

          Swal.fire({
            icon: "error",
            title: "Error al Enviar",
            text: "Hubo un problema al enviar tu consulta. Por favor, intenta nuevamente.",
            confirmButtonText: "Cerrar",
          });
        } finally {
          isSubmitting.value = false;
        }
      }
    };

    const resetForm = () => {
      formData.value = {
        businessName: "",
        name: "",
        lastname: "",
        email: "",
        phone: "",
        division: "",
        comment: "",
      };
    };

    const sanitizePhone = () => {
      formData.value.phone = formData.value.phone.replace(/\D/g, "");
      clearError("phone");
    };

    return {
      title,
      formData,
      errors,
      isSubmitting,
      submitForm,
      clearError,
      sanitizePhone,
    };
  },
};
</script>


<style scoped>
.header {
  background: url("@/assets/images/bg_contacto.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .title {
  text-align: center;
  color: #fff;
}

.header .title h2 {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 800;
  text-transform: uppercase;
}

.form-contacto {
  background: #00a0e1;
  padding: 50px 20px;
}

.info-text {
  text-align: center;
  margin-bottom: 20px;
}

.info-text p {
  font-size: 1.9rem;
  font-weight: 300;
  color: #fff;
}

.contact-form .form-control {
  background: transparent;
  border: none;
  color: #fff;
  margin-bottom: 15px;
  border-bottom: solid 1px #fff;
  border-radius: 0;
}

.contact-form .form-control::placeholder {
  color: #ffffff;
  opacity: 1;
}

.contact-form .btn-enviar {
  background-color: #fff;
  color: #00a0e1;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  float: right;
}

.contact-form .btn-enviar:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.contact-form .has-error {
  border-bottom: 1px solid #ffffff;
}

/* Media Queries */
@media (max-width: 768px) {
  .header {
    height: 150px;
  }

  .header .title h2 {
    font-size: 2rem;
  }

  .form-contacto {
    padding: 30px 10px;
  }

  .info-text p {
    font-size: 1.2rem;
  }

  .contact-form .form-control {
    font-size: 0.9rem;
  }

  .contact-form .btn-enviar {
    float: none;
    width: 100%;
    text-align: center;
  }
}

.error-text {
  color: #ffffff; /* Cambiar el color del texto a blanco para mejor contraste */
  background-color: rgba(255, 0, 0, 0.7); /* Fondo semitransparente rojo */
  font-size: 0.875rem;
  margin-top: 5px;
  display: block;
  padding: 3px 8px;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}

.contact-form .btn-enviar {
  transition: all 0.3s ease;
}

.contact-form .btn-enviar:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.form-contacto select.form-control {
  background-color: transparent; /* Fondo transparente para el select */
  color: #fff; /* Texto blanco */
  border: none; /* Sin bordes */
  border-bottom: 1px solid #fff; /* Línea inferior blanca */
  border-radius: 0; /* Sin bordes redondeados */
  padding: 10px 5px;
  font-size: 1rem;
  appearance: none; /* Remueve el estilo por defecto del navegador */
  -webkit-appearance: none; /* Para Safari */
  -moz-appearance: none; /* Para Firefox */
  cursor: pointer;
  position: relative;
}

/* Estilo de las opciones desplegadas */
.form-contacto select.form-control option {
  background-color: #01145b; /* Fondo azul oscuro para las opciones */
  color: #fff; /* Texto blanco */
  font-size: 1rem;
  padding: 10px;
}

/* Cuando el select tiene el foco */
.form-contacto select.form-control:focus {
  outline: none; /* Remueve el borde por defecto */
  border-bottom: 1px solid #00a0e1; /* Línea inferior azul */
}

/* Opciones al pasar el mouse */
.form-contacto select.form-control option:hover {
  background-color: #00a0e1; /* Fondo azul claro al pasar el mouse */
  color: #fff; /* Texto blanco */
}

/* Ajustes adicionales para icono de desplegable */
.form-contacto select.form-control {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23ffffff' d='M2 0L0 2h4z'/%3e%3c/svg%3e"); /* Flecha */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .form-contacto select.form-control {
    font-size: 0.9rem;
    padding: 8px;
  }

  .form-contacto select.form-control option {
    font-size: 0.9rem;
  }
}
</style>

import { ref } from 'vue';
import { useRouter } from 'vue-router';

export function useImageLoader() {
  const loading = ref(true);
  const filteredViews = ref([]);
  const allViews = ref({ sliders: [], cards: [], blocks: [], buttonLinks: [], articles: [] });
  const router = useRouter();

  const loadImages = async (fetchViewByUrl) => {
    try {
      const response = await fetchViewByUrl();
      const views = response.data;

      const sliders = views.sliders;
      const imagePromises = sliders.map((slide) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = slide.image;
          img.onload = resolve;
          img.onerror = resolve;
        });
      });

      await Promise.all(imagePromises);

      allViews.value.sliders = sliders;
      allViews.value.cards = views.cards;
      allViews.value.blocks = views.blocks;
      allViews.value.buttonLinks = views.buttonLinks;
      allViews.value.articles = views.articles;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        router.push({ name: 'Forbidden' }); 
      } else {
        console.error('Error al cargar los views:', error);
      }
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    filteredViews,
    allViews,
    loadImages,
  };
}
import axios from "@/plugins/axios";

export const fetchUsers = async ({
  search = null,
  orderBy = null,
  orderByMethod = null,
  perPage = 10,
  status = null,
  userType = 2,
  roles = null,
  page = 1,
} = {}) => {
  try {
    const response = await axios.get("/concessionaire-users", {
      params: {
        search,
        orderBy,
        orderByMethod,
        perPage,
        status,
        userType,
        roles,
        page,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const fetchCurrentUser = async () => {
  try {
    const response = await axios.get("/user");
    return response.data;
  } catch (error) {
    console.error("Error fetching current user:", error);
    throw error;
  }
};

export const createUser = async (userData) => {
  const url = '/concessionaire-users';

  try {
    const response = await axios.post(url, userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};


export const updateUser = async (userId, userData) => {
  const url = `/concessionaire-users/${userId}`;

  try {
    const response = await axios.put(url, userData);
    return response.data;
  } catch (error) {
    console.error(`Error updating user with ID ${userId}:`, error);
    throw error;
  }
};
<template>
  <section class="servicios">
    <div class="container">
      <div
        v-if="filteredAndSortedNovedades.length > 3 && !isMobile"
        class="swiper-container"
      >
        <swiper-container ref="swiperRef" init="false" class="mySwiper">
          <swiper-slide
            v-for="(service, index) in filteredAndSortedNovedades"
            :key="index"
          >
            <CardComponent
              :imageSrc="getImage(service.image)"
              :iconSrc="getImage(service.icon)"
              :title="service.title"
              :description="service.content"
              :linkHref="service.link"
              :pdfFile="service.file"
              :buttonColor="service.color"
            />
          </swiper-slide>
        </swiper-container>
      </div>

      <div v-else class="row justify-content-between">
        <CardComponent
          v-for="(service, index) in filteredAndSortedNovedades"
          :key="index"
          :imageSrc="getImage(service.image)"
          :iconSrc="getImage(service.icon)"
          :title="service.title"
          :description="service.content"
          :linkHref="service.link"
          :pdfFile="service.file"
          :buttonColor="service.color"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from "vue";
import { register } from "swiper/element/bundle";
import "swiper/css";
import CardComponent from "./CardComponent.vue";

register();

export default defineComponent({
  name: "ServicesComponent",
  components: {
    CardComponent,
  },
  props: {
    services: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const swiperRef = ref(null);
    const isMobile = ref(false);

    const filteredAndSortedNovedades = computed(() => {
      return props.services
        .filter((service) => service.status === 2)
        .sort((a, b) => a.order - b.order);
    });

    const getImage = (imageName) => {
      if (!imageName) return "";

      if (imageName.startsWith("http://") || imageName.startsWith("https://")) {
        return imageName;
      }

      try {
        return require(`@/assets/images/${imageName}`);
      } catch (e) {
        console.error("Image not found:", imageName);
        return "";
      }
    };

    const handleResize = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      handleResize();
      window.addEventListener("resize", handleResize);

      const swiperContainer = swiperRef.value;
      if (swiperContainer && !isMobile.value) {
        const params = {
          autoHeight: true, 
          navigation: true,
          pagination: { clickable: true },
          autoplay: { delay: 3000, disableOnInteraction: false },
          loop: true,
          slidesPerView: 3,
          spaceBetween: 20,
          injectStyles: [
            `
          .swiper-button-next,
          .swiper-button-prev {
            color: #0bc300;
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          .swiper-button-next {
            right: -10px; /* Aleja el botón Next del slider */
          }
          .swiper-button-prev {
            left: -10px; /* Aleja el botón Prev del slider */
          }v
          .swiper-pagination-bullet {
            background-color: #cccccc; /* Color de los puntos inactivos */
            opacity: 1; /* Asegura que sean visibles */
          }
          .swiper-pagination-bullet-active {
            background-color: #0bc300; /* Color del punto activo */
          }
          .swiper-pagination {
            position: absolute;
            bottom: -5px; /* Ajusta este valor según sea necesario */
            text-align: center;
            transform: translate3d(0, 0, 0);
            z-index: 10;
          }
          .swiper-horizontal > .swiper-pagination-bullets {
            bottom: -5px; /* Asegura que esté más abajo */
          }
          .swiper {
            padding-bottom: 50px; /* Crea espacio adicional para el paginador */
          }
        `,
          ],
        };

        Object.assign(swiperContainer, params);
        swiperContainer.initialize();

        window.swiperInstance = swiperContainer.swiper;
      }
    });

    return {
      filteredAndSortedNovedades,
      getImage,
      swiperRef,
      isMobile,
    };
  },
});
</script>

<style scoped>
.servicios {
  /*   background: url(@/assets/images/bg_servicios.png) no-repeat;
  background-size: cover; */
  background: #005fa8;
  padding: 50px 0;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  align-items: stretch;
}

.row > * {
  flex: 1;
  min-width: 250px;
}

.col-md-4 {
  width: 100% !important;
}

.box-servicio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 20px;
}

.img-container {
  flex: 1;
  height: 154px;
}

.img-servicio {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.titulo-y-boton {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.titulo-y-boton h3 {
  font-size: 1rem;
  font-weight: 500;
}

.titulo-y-boton p {
  font-size: 0.9rem;
  margin: 10px 0;
}

.titulo-y-boton a {
  margin-top: auto;
  align-self: center;
}

.swiper-container {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .row {
    justify-content: center;
  }

  .row > * {
    flex: 0 1 auto;
    max-width: 350px;
    margin: 0 auto;
  }

  .box-servicio {
    margin-bottom: 20px;
  }
}
</style>

<template>
  <div v-if="loading" class="loading-indicator">
    <LoadingIndicator />
  </div>
  <div v-else>
    <!-- Pasar las slides obtenidas a CarouselComponent -->
    <CarouselComponent :slides="allViews.sliders" />
    <!-- Pasar las novedades obtenidas a ServicesComponent -->
    <ServicesComponent v-if="allViews.cards.length > 0" :services="allViews.cards" />
    <BlockComponent
      v-for="block in allViews.blocks"
      :key="block.id"
      :block="block"
    />
    <NovedadComponent v-if="allViews.articles.length > 0" :articles="allViews.articles"/>
    <SucursalComponent />

      <ButtonLinkComponent v-if="allViews.buttonLinks.length > 0" :buttonLinks="allViews.buttonLinks" />
    <AladdaComponent />
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useImageLoader } from '@/composables/useImageLoader';
import { fetchViewByUrl } from "@/services/api/view.js";

import CarouselComponent from "@/components/carousel/CarouselComponent.vue";
import ServicesComponent from "@/components/servicios/Servicio.vue";
import NovedadComponent from "@/components/novedades/Novedad.vue";
import SucursalComponent from "@/components/sucursal/Sucursal.vue";
import AladdaComponent from "@/components/aladda/Aladda.vue";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";
import BlockComponent from "@/components/block/BlockComponent.vue";
import ButtonLinkComponent from "@/components/bottonLink/ButtonLinkComponent.vue";

const { loading, allViews, loadImages } = useImageLoader();

onMounted(async () => {
  await loadImages(fetchViewByUrl);
});
</script>


import { ref } from "vue";

export default function useModals() {
  // Estado y lógica para el modal de registros
  const isRegistrationModalOpen = ref(false);
  const selectedRegistration = ref(null);

  const viewMoreRegistration = (registration) => {
    selectedRegistration.value = registration;
    isRegistrationModalOpen.value = true;
  };

  const closeRegistrationModal = () => {
    isRegistrationModalOpen.value = false;
  };

  // Estado y lógica para el modal de posiciones
  const isPositionsModalOpen = ref(false);
  const selectedPositions = ref([]);

  const openPositionsModal = (positions) => {
    selectedPositions.value = positions;
    isPositionsModalOpen.value = true;
  };

  const closePositionsModal = () => {
    isPositionsModalOpen.value = false;
  };

  return {
    // Registro
    isRegistrationModalOpen,
    selectedRegistration,
    viewMoreRegistration,
    closeRegistrationModal,

    // Posiciones
    isPositionsModalOpen,
    selectedPositions,
    openPositionsModal,
    closePositionsModal,
  };
}

<template>
  <div class="aliquot-container">
    <div class="aliquot-content">
      <div class="aliquot-map">
        <MapChartComponent
          @province-selected="updateProvince"
        />
      </div>
      <div class="aliquot-table">
        <AliquotTableComponent
          :selectedProvince="selectedProvince"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MapChartComponent from "@/components/aliquot/MapChartComponent.vue";
import AliquotTableComponent from "@/components/aliquot/AliquotTableComponent.vue";
import { ref } from "vue";

export default {
  name: "AliquotComponent",
  components: {
    MapChartComponent,
    AliquotTableComponent,
  },
  setup() {
    const selectedProvince = ref(null);

    const updateProvince = (province) => {
      selectedProvince.value = {
        countryName: province.CNTRY_NAME,
        type: province.TYPE,
        id: province.id,
        name: province.name,
      };
    };

    return {
      selectedProvince,
      updateProvince,
    };
  },
};
</script>

<style scoped>
.aliquot-container {
  padding: 2rem;
  text-align: center;
}

.aliquot-content {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
  margin: 0 6rem;
  flex-wrap: wrap; 
}

.aliquot-map,
.aliquot-table {
  flex: 1;
  max-width: 60%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.aliquot-table {
  overflow-y: auto;
  text-align: left;
}

.chartdiv {
  height: 100%;
}


@media screen and (max-width: 1024px) {
  .aliquot-content {
    margin: 0 4rem;
    gap: 1.5rem;
  }

  .aliquot-map,
  .aliquot-table {
    max-width: 100%;
    flex: none;
  }
}

@media screen and (max-width: 768px) {
  .aliquot-content {
    flex-direction: column;
    margin: 0 2rem; 
    gap: 1rem;
  }

  .aliquot-map,
  .aliquot-table {
    max-width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .aliquot-container {
    padding: 1rem;
  }

  .aliquot-content {
    margin: 0 1rem; 
  }

  .aliquot-map,
  .aliquot-table {
    font-size: 0.9rem;
  }
}
</style>

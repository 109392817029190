<template>
  <div id="motos-informacion">
    <section class="header">
      <div class="title">
        <div class="container">
          <div class="col-12">
            <h3>
              <img :src="iconPath" alt="Documentación Necesaria" height="55" />
              ¿Qué documentación necesito para circular en moto?
            </h3>
          </div>
        </div>
      </div>
    </section>

    <section class="informacion">
      <div class="container">
        <div class="row">
          <div class="col-12 text-left">
            <p>
              Antes de salir con tu moto, es necesario chequear que tengas toda la documentación correspondiente
              y la indumentaria de protección adecuada. La utilización del casco es obligatoria para circular con tu motovehículo.
            </p>
            <p>
              Además, recordá que los guantes y la campera, te resguardan tu cuerpo ante posibles caídas.
              Teniendo ésto en cuenta, te facilitamos a continuación el detalle de la documentación que no puede faltarte:
              <br /><br />
              • Tu DNI<br />
              • Tu Licencia Nacional de conducir<br />
              • Cedula verde o azul<br />
              • Comprobante de seguro en vigencia<br />
              • Comprobante de pago del impuesto a la radicación del vehículo<br />
              • RTO o VTV (en las provincias que sea obligatoria)<br />
              • Patentes legibles, normalizadas y sin aditamentos<br /><br />
              Si viajás con acompañante, recordá que el casco también es obligatorio para la persona que vaya con vos.
              No uses el celular mientras manejás. ¡Evitá cualquier elemento que pueda distraerte de tu ruta!
              <br /><br />
              ¡Prevengamos accidentes y disfrutemos de un manejo seguro y en regla!
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <br /><br />
            <button @click="goBack" class="btn-volver">VOLVER</button>
            <br /><br />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";


export default {
  name: "DocumentacionMotoComponent",
  setup() {
    const iconPath = require("@/assets/images/icon_documentacion_necesaria.png");
    const router = useRouter();

    const goBack = () => {
      if (window.history.length > 1) {
        router.go(-1);
      } else {
        router.push("/"); 
      }
    };

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      iconPath,
      goBack,
    };
  },
};
</script>

<style scoped>
#motos-informacion .header {
  background: #001f5b;
  height: 125px;
  position: relative;
  width: 100%;
}
#motos-informacion .header .title {
  position: absolute;
  bottom: 0;
  width: 100%;
}
#motos-informacion .header .title h3 {
  color: #ffffff;
  padding: 0 0 25px 0;
  text-transform: uppercase;
}
#motos-informacion .header img {
  margin-right: 25px;
}
#motos-informacion .informacion {
  background: #ffffff;
  padding: 45px 0 0 0;
  text-align: left;
}
#motos-informacion .informacion h3 {
  color: #00a0e1;
  margin-bottom: 25px;
  text-transform: uppercase;
}
#motos-informacion .informacion h5 {
  color: #00a0e1;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
}
#motos-informacion .btn-volver {
  color: #fff;
  background: #01145b;
  font-size: 1.2rem;
  font-weight: 800;
  padding: 10px 80px;
  border: none;
  cursor: pointer;
}
#motos-informacion .btn-volver:hover {
  background: #00a0e1;
}
</style>

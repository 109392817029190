<template>
  <header class="header">
    <div class="container">
      <div class="header-top">
        <div class="header-left">
          <LogoComponent @logo-clicked="resetActivePage" />
        </div>
        <div class="header-right">
          <transition name="fade" mode="out-in">
            <div :key="`${isLoggedIn}-${isMobile}-${Date.now()}`">
              <LoginButtonComponent v-if="!isLoggedIn" key="login" />
              <UserMenuComponent
                :userName="user?.name || ''"
                :concessionaires="user?.concessionaires || []"
                @logout="handleLogout"
                :is-mobile="isMobile"
                key="userMenu"
              />
            </div>
          </transition>
        </div>
      </div>

      <div class="header-bottom">
        <NavbarComponent
          :active-page="activePage"
          @update-active-page="setActivePage"
          :is-mobile="isMobile"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { computed, ref, onMounted, onBeforeUnmount, watch } from "vue";
import { useStore } from "vuex";
//import { useRouter } from "vue-router";
import LogoComponent from "@/components/header/LogoComponent.vue";
import NavbarComponent from "@/components/header/navbar/NavbarComponent.vue";
import LoginButtonComponent from "@/components/header/login/LoginButtonComponent.vue";
import UserMenuComponent from "@/components/header/UserMenuComponent.vue";

export default {
  name: "HeaderComponent",
  components: {
    LogoComponent,
    NavbarComponent,
    LoginButtonComponent,
    UserMenuComponent,
  },
  setup() {
    const store = useStore();
    // const router = useRouter();

    const isLoggedIn = computed(() => store.getters.isAuthenticated);
    const user = computed(() => store.getters.getUser);

    const handleLogout = () => {
      store.dispatch("logout");
      //router.push({ name: "home" });
    };

    const activePage = ref("");
    const isMobile = ref(false);

    const setActivePage = (page) => {
      activePage.value = page;
    };

    const resetActivePage = () => {
      activePage.value = "";
    };

    const checkIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };
    watch(user, (newValue) => {
      if (!newValue || Object.keys(newValue).length === 0) {
        handleLogout();
      }
    });

    onMounted(() => {
      checkIsMobile();
      window.addEventListener("resize", checkIsMobile);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", checkIsMobile);
    });

    onMounted(() => {
      if (!user.value || Object.keys(user.value).length === 0) {
        handleLogout();
      }
    });

    return {
      isLoggedIn,
      user,
      handleLogout,
      activePage,
      isMobile,
      setActivePage,
      resetActivePage,
    };
  },
};
</script>

<style scoped>
.header {
  background-color: #fff;
  padding: 0px 20px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.header-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.header-bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .header {
    padding: 10px;
  }
}
</style>
